<template>
	<div class="box01">
		<v-crumbs :crumbsType="16"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="$publicFun.get_link('/info/enterprise/add', {})">新增</div>
				<div class="m_r10">
					<v-select02 :p_text="'名称/性质/国家'" @serch="get_serch" @empty="get_empty"></v-select02>
				</div>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column label="合作性质" width="150">
						<template slot-scope="scope">
							{{scope.row.partner_type == 1?'供应商': scope.row.partner_type == 2?'客户': scope.row.partner_type == 3?'其他': ''}}
						</template>
					</el-table-column>
					<el-table-column prop="company_name" label="公司名称"></el-table-column>
					<el-table-column prop="country_name" label="所属国家" width="120"></el-table-column>
					<el-table-column label="结算账期" width="120">
						<template slot-scope="scope">
							{{scope.row.finance.payment_days.name}}
						</template>
					</el-table-column>
					<el-table-column label="结算周期" width="130">
						<template slot-scope="scope">
							{{scope.row.finance.interval}}
						</template>
					</el-table-column>
					<el-table-column label="合作协议" width="120">
						<template slot-scope="scope">
							{{scope.row.is_partner == 1?'有': scope.row.is_partner == 2?'无': ''}}
						</template>
					</el-table-column>
					<el-table-column prop="createtime_text" label="添加时间" width="220"></el-table-column>
					<el-table-column fixed="right" label="操作" width="220">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/info/enterprise/add?id=' + scope.row.id, {})">查看</p>
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/info/enterprise/add?bj=true&id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f pointer_" @click="clickDel(scope.row.id)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select01 from '../../components/select/index.vue'; // 选择器
	import select02 from '../../components/select/index01.vue'; // 搜索框

	import {
		companyList,
		companyDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select01': select01,
			'v-select02': select02,
			'v-screen': screen
		},
		data() {
			return {
				list: [],
				total: null,
				formInfo: {
					page: 1,
					limit: 10,
					keyword: ''
				},
			}
		},
		created() {
			this.getCompanyList();
		},
		methods: {
			// 获取企业列表
			getCompanyList() {
				companyList(this.formInfo).then(res => {
					this.list = res.data.data
					this.total = res.data.total
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.getCompanyList();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) {
					this.$message('请输入搜索内容!');
					return
				}

				this.formInfo.page = 1;
				this.formInfo.keyword = e;
				this.getCompanyList();
				window.scroll(0, 0);
			},
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.keyword = e;
				this.getCompanyList();
				window.scroll(0, 0);
			},
			// 删除
			clickDel(id) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					companyDel({
						id: id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.getCompanyList()
						this.$message.success('删除成功')
					})
				})
			},
			handleSelectionChange(e) {
				// 勾选的数据
			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
