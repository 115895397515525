// 新增订舱单
const moduleAdd = {
    namespaced:true,
    state:{
        formInfo:{}
    },
    mutations:{
        getFormInfo(state,val){
            state.formInfo = val
        }
    },
    actions:{}
}

export default moduleAdd