<template>
	<div class="box01">
		<v-crumbs :crumbsType="18"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="clickAdd">新增</div>
				<v-import :type="1" @get_daoru_success="get_daoru_success"></v-import>
				<!-- <div class="btn01 m_r10">导入</div> -->
			</div>
		</div>
		<div class="form_box">
			<div style="flex: 1;overflow: hidden;">
				<template>
					<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" fixed width="46"></el-table-column>
						<el-table-column prop="id" label="ID" width="70"></el-table-column>
						<el-table-column prop="code" label="英文代码" width="160"></el-table-column>
						<el-table-column prop="name" label="中文名称" width="160"></el-table-column>
						<el-table-column prop="country_name" label="国家" width="160"></el-table-column>
						<el-table-column prop="service_name" label="所属服务类型"></el-table-column>
						<el-table-column prop="createtime_text" label="添加时间"></el-table-column>
						<el-table-column fixed="right" label="操作">
							<template slot-scope="scope">
								<div class="flex04">
									<!-- <p class="c_6d9fff m_r10 pointer_">查看</p> -->
									<p class="c_6d9fff m_r10 pointer_" @click="clickEdit(scope.row)">编辑</p>
									<p class="c_ff6f6f pointer_" @click="clickDel(scope.row)">删除</p>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</template>
				<div class="pagination">
					<el-pagination background :page-size="fromInfo.limit" :current-page="fromInfo.page" layout="prev, pager, next, jumper"
					 :total="total" @current-change="currentChange"></el-pagination>
				</div>
			</div>
			<div class="form_right_nav_wrapper">
				<v-right-nav :index="4"></v-right-nav>
			</div>
		</div>
		<!-- 新建 -->
		<v-modal :dialogVisible="dialogVisible" :type="5" @getModalClose="getModalClose" :state="isState" :editVal="editVal"
		 v-if="dialogVisible" @submitEnd="submitEnd"></v-modal>
	</div>
</template>

<script>
	import daoru from '../../components/Import/index.vue'; // 导入
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select01 from '../../components/select/index.vue'; // 选择器
	import select02 from '../../components/select/index01.vue'; // 搜索框
	import screen from '../../components/screen/index.vue'; // 高级搜索
	import rightNav from '../../components/nav/index.vue'; // 右侧导航
	import modal from '../../components/set-modal/index.vue'; // 新建模态

	import {
		startHarbor,
		delStartHarbor
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select01': select01,
			'v-select02': select02,
			'v-screen': screen,
			'v-right-nav': rightNav,
			'v-modal': modal,
			'v-import': daoru
		},
		data() {
			return {
				list: [],
				total: null,
				isState: '',
				editVal: '',
				idList: [],
				dialogVisible: false,
				fromInfo: {
					page: 1,
					limit: 10
				},
			}
		},
		created() {
			this.getStartHarborList()
		},
		methods: {
			get_daoru_success() {
				this.getStartHarborList();
			},
			// 获取列表
			getStartHarborList() {
				startHarbor(this.fromInfo).then(res => {
					this.total = res.data.total
					this.list = res.data.data
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.fromInfo.page = newPage
				this.getStartHarborList()
				window.scroll(0, 0);
			},
			clickAdd() {
				this.isState = 'add'
				this.dialogVisible = true
			},
			clickEdit(val) {
				let arr_ = val.server_id.split(',')
				let arr_mum = [];

				arr_.forEach((n, k) => {
					arr_mum.push(Number(n))
				})

				val.server_id = arr_mum;

				this.editVal = val
				this.isState = 'edit'
				this.dialogVisible = true
			},
			submitEnd() {
				if (this.isState === 'add') {
					this.fromInfo.page = 1;
				}

				this.dialogVisible = false
				this.getStartHarborList()
				this.editVal = ''
			},
			clickDel(id) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delStartHarbor(id).then(res => {
						if (this.fromInfo.page != 1 && this.list.length == 1) {
							this.fromInfo.page--
						}
						this.getStartHarborList()
						this.$message.success('删除成功')

					})
				})
			},
			getModalClose(e) {
				this.editVal = ''
				this.dialogVisible = e
			},
			handleSelectionChange(e) { //复选按钮
				let arr = []
				if (e.length) {
					e.forEach(val => {
						arr.push(val.id)
					})
				}
				this.idList = arr
			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}

	.form_box {
		display: flex;
	}
</style>
