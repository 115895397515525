<template>
	<div class="wx-modal_wrapper">
		<el-dialog title="" custom-class="dialog_wrapper" :visible.sync="dialogVisible" :show-close="false" width="26%" modal
		 :close-on-click-modal="false">
			<div class="wx-modal_top">
				<p class="font_16">{{state=='add' ? '新增' : '编辑'}}</p>
				<i @click="get_close"></i>
			</div>
			<div style="padding: 0 34px;">
				<el-form :model="formInfo" ref="formInfo" :rules="fromRules">
					<div class="hid_" v-if="type === 1">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">服务类型名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入服务类型名称" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 2">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">运输流向名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入运输流向名称" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 3">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">运输方式名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入运输方式名称" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 4">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">服务范围名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入服务范围名称" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 5 || type === 6">
						<el-form-item prop="input1.code">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">英文代码名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入英文代码名称" v-model="formInfo.input1.code" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input1.name">
							<div class="flex01">
								<div class="font_12 w_84">中文名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入中文名称" v-model="formInfo.input1.name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input1.country_name">
							<div class="flex01">
								<div class="font_12 w_84">国家：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入国家" v-model="formInfo.input1.country_name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input1.server_id">
							<div class="flex01">
								<div class="font_12 w_84">所属服务类型：</div>
								<div class="wx-modal_input">
									<el-select v-model="formInfo.input1.server_id" multiple placeholder="请选择所属服务类型" @change="get_change">
										<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
									</el-select>
									<div class="icon flex04">
										<img src="../../assets/01.png" />
									</div>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m02" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 7">
						<el-form-item prop="input2.name">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">中文名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入中文名称" v-model="formInfo.input2.name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input2.english_name">
							<div class="flex01">
								<div class="font_12 w_84">英文名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入英文名称" v-model="formInfo.input2.english_name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 8">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">结算周期：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入结算周期" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 9">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">结算方式：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入结算方式" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 10">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">线路名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入线路名称" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 11">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">包装规格：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入包装规格" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 12">
						<el-form-item prop="input">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">币制名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入币制名称" v-model="formInfo.input" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m01" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 13">
						<el-form-item prop="input3.code">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">城市简码：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入城市简码" v-model="formInfo.input3.code" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input3.english_name">
							<div class="flex01">
								<div class="font_12 w_84">英文名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入英文名称" v-model="formInfo.input3.english_name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input3.name">
							<div class="flex01">
								<div class="font_12 w_84">中文名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入中文名称" v-model="formInfo.input3.name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input3.country_name">
							<div class="flex01">
								<div class="font_12 w_84">国家：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入国家" v-model="formInfo.input3.country_name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m02" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 14 || type === 15">
						<el-form-item prop="input4.aviation_code">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">{{type === 14?'航司代码': '船司代码'}}：</div>
								<div class="wx-modal_input">
									<el-input :placeholder="type === 14?'请输入航司代码': '请输入船司代码'" v-model="formInfo.input4.aviation_code" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input4.aviation_name">
							<div class="flex01">
								<div class="font_12 w_84">{{type === 14?'航司名称': '船司名称'}}：</div>
								<div class="wx-modal_input">
									<el-input :placeholder="type === 14?'请输入航司名称': '请输入船司名称'" v-model="formInfo.input4.aviation_name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m02" @click="typeSubmit">保存</div>
					</div>
					<div class="hid_" v-if="type === 16">
						<el-form-item prop="input5.code">
							<div class="flex01 m_t20">
								<div class="font_12 w_84">HS编码：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入H5编码" v-model="formInfo.input5.code" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.name">
							<div class="flex01">
								<div class="font_12 w_84">商品名称：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入商品名称" v-model="formInfo.input5.name" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.one_unit">
							<div class="flex01">
								<div class="font_12 w_84">法定第一单位：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入法定第一单位" v-model="formInfo.input5.one_unit" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.two_unit">
							<div class="flex01">
								<div class="font_12 w_84">法定第二单位：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入法定第二单位" v-model="formInfo.input5.two_unit" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.one_tariff">
							<div class="flex01">
								<div class="font_12 w_84">进口关税：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入进口关税" v-model="formInfo.input5.one_tariff" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.two_tariff">
							<div class="flex01">
								<div class="font_12 w_84">进口增值税：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入进口增值税" v-model="formInfo.input5.two_tariff" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.three_tariff">
							<div class="flex01">
								<div class="font_12 w_84">出口退税率：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入出口退税率" v-model="formInfo.input5.three_tariff" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="input5.regulatory">
							<div class="flex01">
								<div class="font_12 w_84">监管条件：</div>
								<div class="wx-modal_input">
									<el-input placeholder="请输入监管条件" v-model="formInfo.input5.regulatory" clearable></el-input>
								</div>
							</div>
						</el-form-item>
						<div class="btn01 m02" @click="typeSubmit">保存</div>
					</div>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		serviceType,
		paramsApi
	} from '@/api/index';

	export default {
		props: {
			dialogVisible: {
				type: Boolean
			},
			type: {
				type: [Number, String]
			},
			state: {
				type: String
			},
			editVal: {
				type: [String, Object]
			}
		},
		data() {
			return {
				options: [],
				formInfo: {
					input: this.editVal.name || '',
					input1: {
						id: this.editVal.id || null,
						code: this.editVal.code || '',
						name: this.editVal.name || '',
						country_name: this.editVal.country_name || '',
						server_id: this.editVal.server_id || ''
					},
					input2: {
						id: this.editVal.id || null,
						name: this.editVal.name || '',
						english_name: this.editVal.english_name || '',
					},
					input3: {
						id: this.editVal.id || null,
						code: this.editVal.code || '',
						name: this.editVal.name || '',
						english_name: this.editVal.english_name || '',
						country_name: this.editVal.country_name || ''
					},
					input4: {
						id: this.editVal.id || null,
						aviation_code: this.editVal.ship_code || '',
						aviation_name: this.editVal.ship_name || ''
					},
					input5: {
						id: this.editVal.id || null,
						code: this.editVal.code || '',
						name: this.editVal.name || '',
						one_unit: this.editVal.one_unit || '',
						two_unit: this.editVal.two_unit || '',
						one_tariff: this.editVal.one_tariff || '',
						two_tariff: this.editVal.two_tariff || '',
						three_tariff: this.editVal.three_tariff || '',
						regulatory: this.editVal.regulatory || '',
					}
				},
				fromRules: { // 校验
					input: [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}, ],
					"input1.code": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input1.name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input1.country_name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input1.server_id": [{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}],
					"input2.name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input2.english_name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input3.code": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input3.name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input3.english_name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input3.country_name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input4.aviation_code": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input4.aviation_name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input5.code": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input5.name": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input5.one_unit": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input5.two_unit": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
					"input5.one_tariff": [{
							required: true,
							message: '请输入内容',
							trigger: 'blur'
						},
						{
							// type: 'number',
							message: '请输入数字',
							trigger: 'blur'
						}
					],
					"input5.two_tariff": [{
							required: true,
							message: '请输入内容',
							trigger: 'blur'
						},
						{
							// type: 'number',
							message: '请输入数字',
							trigger: 'blur'
						}
					],
					"input5.three_tariff": [{
							required: true,
							message: '请输入内容',
							trigger: 'blur'
						},
						{
							// type: 'number',
							message: '请输入数字',
							trigger: 'blur'
						}
					],
					"input5.regulatory": [{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}],
				}
			}
		},
		created() {
			if (this.type == 5 || this.type == 6) {
				this.getTypeList();
			}
		},
		methods: {
			// 
			get_change(e) {
				console.log(e)
			},
			// 获取列表
			getTypeList() {
				serviceType(this.fromInfo).then(res => {
					this.options = res.data.data
				})
			},
			// 关闭
			get_close() {
				this.$emit('getModalClose', false)
			},
			// 添加或修改事件
			typeSubmit() {
				this.$refs.formInfo.validate((valid) => {
					if (valid) {
						if (this.state == 'add') { // 添加
							if (this.type == 1) {
								var addUrl = 'admin/service_type/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 2) {
								var addUrl = 'admin/transportation/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 3) {
								var addUrl = 'admin/transport/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 4) {
								var addUrl = 'admin/services_scope/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 5) {
								var addUrl = 'admin/shipment_harbor/add';

								this.formInfo.input1.server_id = this.formInfo.input1.server_id.join(',')

								var addData = this.formInfo.input1;
							} else if (this.type == 6) {
								var addUrl = 'admin/bourn_harbor/add';
								
								this.formInfo.input1.server_id = this.formInfo.input1.server_id.join(',')

								var addData = this.formInfo.input1;
							} else if (this.type == 7) {
								var addUrl = 'admin/cost_name/add';

								var addData = this.formInfo.input2;
							} else if (this.type == 8) {
								var addUrl = 'admin/payment_days/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 9) {
								var addUrl = 'admin/pay_type/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 10) {
								var addUrl = 'admin/circuit/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 11) {
								var addUrl = 'admin/packing/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 12) {
								var addUrl = 'admin/currency/add';

								var addData = {
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 13) {
								var addUrl = 'admin/intern_city/add';

								var addData = this.formInfo.input3;
							} else if (this.type == 14) {
								var addUrl = 'admin/aviation_company/add';

								var addData = this.formInfo.input4;
							} else if (this.type == 15) {
								var addUrl = 'admin/ship_company/add';

								var addData = {
									ship_code: this.formInfo.input4.aviation_code,
									ship_name: this.formInfo.input4.aviation_name
								}
							} else if (this.type == 16) {
								var addUrl = 'admin/hs_code/add';

								var addData = this.formInfo.input5;
							}

							paramsApi(addUrl, addData).then(res => {
								this.$emit('submitEnd');
								this.$message.success('添加成功');
							})
						}

						if (this.state == 'edit') { // 修改
							if (this.type == 1) {
								var editUrl = 'admin/service_type/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 2) {
								var editUrl = 'admin/transportation/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 3) {
								var editUrl = 'admin/transport/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 4) {
								var editUrl = 'admin/services_scope/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 5) {
								var editUrl = 'admin/shipment_harbor/edit';
								
								this.formInfo.input1.server_id = this.formInfo.input1.server_id.join(',')

								var editData = this.formInfo.input1;
							} else if (this.type == 6) {
								var editUrl = 'admin/bourn_harbor/edit';
								
								this.formInfo.input1.server_id = this.formInfo.input1.server_id.join(',')
								
								var editData = this.formInfo.input1;
							} else if (this.type == 7) {
								var editUrl = 'admin/cost_name/edit';

								var editData = this.formInfo.input2
							} else if (this.type == 8) {
								var editUrl = 'admin/payment_days/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 9) {
								var editUrl = 'admin/pay_type/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 10) {
								var editUrl = 'admin/circuit/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 11) {
								var editUrl = 'admin/packing/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 12) {
								var editUrl = 'admin/currency/edit';

								var editData = {
									id: this.editVal.id,
									name: this.formInfo.input.trim()
								}
							} else if (this.type == 13) {
								var editUrl = 'admin/intern_city/edit';

								var editData = this.formInfo.input3;
							} else if (this.type == 14) {
								var editUrl = 'admin/aviation_company/edit';

								var editData = this.formInfo.input4;
							} else if (this.type == 15) {
								var editUrl = 'admin/ship_company/edit';

								var editData = {
									id: this.editVal.id,
									ship_name: this.formInfo.input4.aviation_name,
									ship_code: this.formInfo.input4.aviation_code
								}
							} else if (this.type == 16) {
								var editUrl = 'admin/hs_code/edit';

								var editData = this.formInfo.input5;
							}

							paramsApi(editUrl, editData).then(res => {
								this.$emit('submitEnd')
								this.$message.success('修改成功!')
							})
						}
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.m01 {
		margin: 228px auto 26px auto;
	}

	.m02 {
		margin: 42px auto 26px auto;
	}

	.wrapper {
		width: 50%;
		display: inline-block;
		vertical-align: top;
	}

	.dialog_wrapper {
		border-radius: 8px !important;

		.el-form-item__error {
			padding-left: 84px;
			padding-top: 5px;
			padding-bottom: 5px;
		}

		.el-dialog__header {
			padding: 0;
		}

		.el-dialog__body {
			padding: 0;
		}

		.el-upload {
			display: block;
		}

		.i .el-image {
			margin: 0;
		}

		.wx-modal_input {
			.el-input__inner {
				border-radius: 10px;
				border-color: #D2D2D2;

				&:focus {
					border-color: #D2D2D2;
				}
			}

			.el-textarea__inner {
				border-radius: 10px;
				border-color: #D2D2D2;

				&:focus {
					border-color: #D2D2D2;
				}
			}
		}

		.el-select {
			display: block;
		}
	}

	.wx-modal_top {
		height: 60px;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #D0D0D0;

		i {
			top: 17px;
			right: 20px;
			display: block;
			width: 26px;
			height: 26px;
			cursor: pointer;
			position: absolute;
			background: url(../../assets/wx/05@2x.png) no-repeat center;
			background-size: 100% 100%;
		}
	}

	.wx-up_image {
		margin-top: 23px;
	}

	.wx-modal_input {
		flex: 1;
		position: relative;

		.icon {
			top: 0;
			right: 0;
			width: 28px;
			bottom: 0;
			position: absolute;
			background-color: #5B8EF0;
			border-radius: 0 10px 10px 0;

			img {
				width: 10px;
			}
		}
	}

	.up_icon {
		display: block;
		width: 60px;
		height: 60px;
		background: url(../../assets/wx/06@2x.png) no-repeat center;
		background-size: 100% 100%;
	}
</style>
