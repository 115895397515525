import Vue from "vue";
import VueRouter from "vue-router";

import Login from '../views/login/index.vue'; // 登录 *
import OfferList from '../views/offer/index.vue'; // 报价查询 *
import OfferAdd from '../views/offer/add.vue'; // 新增报价 *
import OfferEdit from '../views/offer/edit.vue'; // 成本编辑/编辑 *
import FormList from '../views/offer/form_list.vue'; // 报价模板列表 *
import FormEdit from '../views/offer/form_edit.vue'; // 报价模板编辑 *
import OrderList from '../views/order/index.vue'; // 订单列表 *
import OrderAdd from '../views/order/add.vue'; // 新增订单 *
import OrderEdit from '../views/order/edit.vue'; // 成本编辑/编辑 *
import OrderModuleList from '../views/order/order_list.vue'; // 订舱单查询 *
import OrderModuleAdd from '../views/order/module_add.vue'; // 新增/编辑订舱单 *
import SettleList from '../views/settle/index.vue'; // 应收结算列表 *
import SettleEdit from '../views/settle/edit.vue'; // 应收结算编辑 *
import SettleEdit1 from '../views/settle/edit1.vue'; // 应收完成编辑 *
import SettlePayList from '../views/settle/pay_index.vue'; // 应付结算列表 *
import SettlePayEdit from '../views/settle/pay_edit.vue'; // 应付结算编辑 *
import SettlePayEdit1 from '../views/settle/pay_edit1.vue'; // 应付完成编辑 *
import SettleFinishList from '../views/settle/finish_index.vue'; // 应收结算完成列表 *
import SettlePayFinishList from '../views/settle/pay_finish_index.vue'; // 应付结算完成列表 *
import BusinessList from '../views/chart/business_index.vue'; // 业务数据 *
import FinanceList from '../views/chart/finance_index.vue'; // 财务数据 *
import EnterpriseList from '../views/basics/enterprise_list.vue'; // 企业管理 *
import EnterpriseAdd from '../views/basics/enterprise_add.vue'; // 企业管理新增、编辑 *
import ParameterSet01 from '../views/basics/set01.vue'; // 服务类型 *
import ParameterSet02 from '../views/basics/set02.vue'; // 运输流向 *
import ParameterSet03 from '../views/basics/set03.vue'; // 运输方式 *
import ParameterSet04 from '../views/basics/set04.vue'; // 服务范围 *
import ParameterSet05 from '../views/basics/set05.vue'; // 起运地/起运港 *
import ParameterSet06 from '../views/basics/set06.vue'; // 目的地/目的港 *
import ParameterSet07 from '../views/basics/set07.vue'; // 费用名称 *
import ParameterSet08 from '../views/basics/set08.vue'; // 结算账期 *
import ParameterSet09 from '../views/basics/set09.vue'; // 结算方式 *
import ParameterSet10 from '../views/basics/set10.vue'; // 线路 *
import ParameterSet11 from '../views/basics/set11.vue'; // 包装 *
import ParameterSet12 from '../views/basics/set12.vue'; // 币制 *
import ParameterSet13 from '../views/basics/set13.vue'; // 国际城市 *
import ParameterSet14 from '../views/basics/set14.vue'; // 航空公司 *
import ParameterSet15 from '../views/basics/set15.vue'; // 船公司 *
import ParameterSet16 from '../views/basics/set16.vue'; // HS编码 *
import VoucherType from '../views/basics/voucher_type.vue'; // 结算方式 *
import Parament01 from '../views/weixin/paramet01.vue'; // 轮播图列表 *
import Parament02 from '../views/weixin/paramet02.vue'; // 服务列表 *
import Parament03 from '../views/weixin/paramet03.vue'; // 帮助中心 *
import HelpAdd from '../views/weixin/help_add.vue'; // 帮助中心新增、编辑 *
import Parament04 from '../views/weixin/paramet04.vue'; // 包装列表 *
import ConsultList from '../views/weixin/consult_list.vue'; // 立即咨询列表 *
import GoodsList from '../views/weixin/goods_list.vue'; // 货物询价列表 *
import GoodsDetail from '../views/weixin/goods_detail.vue'; // 货物询价查看 *
import About from '../views/weixin/about.vue'; // 关于我们 *
import UserList from '../views/weixin/user_list.vue'; // 用户列表 *
import Administrator from '../views/authority/administrator.vue'; // 管理员列表 *
import AdministratorAdd from '../views/authority/admin_add.vue'; // 管理员添加/编辑 *
import Role from '../views/authority/role.vue'; // 角色组 *
import RoleAdd from '../views/authority/role_add.vue'; // 角色添加/编辑 *
import orderPdf from '../views/order/pdf.vue'; // 订舱单导出pdf
import offerPdf from '../views/offer/pdf.vue'; // 报价单导出pdf
import settlePdf from '../views/settle/pdf.vue'; // 应收结算单导出pdf
import settlePayPdf from '../views/settle/pay_pdf.vue'; // 应出结算单导出pdf
import collectPdf from '../views/settle/collect_pdf.vue'; // 汇总账单pdf
import collectList from '../views/settle/collect_list.vue'; // 汇总账单列表
import collectShow from '../views/settle/collect_view.vue'; // 汇总账单详情列表

import mxy from '../views/mxy.vue'; // 测试用

Vue.use(VueRouter);

const routes = [{
	path: "/",
	name: "OfferList",
	component: OfferList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/login",
	name: "Login",
	component: Login,
}, {
	path: "/offer/add",
	name: "OfferAdd",
	component: OfferAdd,
}, {
	path: "/offer/edit",
	name: "OfferEdit",
	component: OfferEdit,
}, {
	path: "/offer/form/list",
	name: "FormList",
	component: FormList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/offer/form/edit",
	name: "FormEdit",
	component: FormEdit,
}, {
	path: "/order/list",
	name: "OrderList",
	component: OrderList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/order/add",
	name: "OrderAdd",
	component: OrderAdd,
}, {
	path: "/order/edit",
	name: "OrderEdit",
	component: OrderEdit,
}, {
	path: "/order/module/list",
	name: "OrderModuleList",
	component: OrderModuleList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/order/module/add",
	name: "OrderModuleAdd",
	component: OrderModuleAdd,
}, {
	path: "/settle/list",
	name: "SettleList",
	component: SettleList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/settle/edit",
	name: "SettleEdit",
	component: SettleEdit,
}, {
	path: "/settle/edit1",
	name: "SettleEdit1",
	component: SettleEdit1,
}, {
	path: "/settle/pay/list",
	name: "SettlePayList",
	component: SettlePayList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/settle/pay/edit",
	name: "SettlePayEdit",
	component: SettlePayEdit,
},
{
	path: "/settle/pay/edit1",
	name: "SettlePayEdit1",
	component: SettlePayEdit1,
},{
	path: "/settle/finish/list",
	name: "SettleFinishList",
	component: SettleFinishList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/settle/pay_finish/list",
	name: "SettlePayFinishList",
	component: SettlePayFinishList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/tubiao/business/list",
	name: "BusinessList",
	component: BusinessList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/tubiao/finance/list",
	name: "FinanceList",
	component: FinanceList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/info/enterprise/list",
	name: "EnterpriseList",
	component: EnterpriseList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
}, {
	path: "/info/enterprise/add",
	name: "EnterpriseAdd",
	component: EnterpriseAdd,
}, {
	path: "/info/parameter/set01",
	name: "ParameterSet01",
	component: ParameterSet01,
}, {
	path: "/info/parameter/set02",
	name: "ParameterSet02",
	component: ParameterSet02,
}, {
	path: "/info/parameter/set03",
	name: "ParameterSet03",
	component: ParameterSet03,
}, {
	path: "/info/parameter/set04",
	name: "ParameterSet04",
	component: ParameterSet04,
}, {
	path: "/info/parameter/set05",
	name: "ParameterSet05",
	component: ParameterSet05,
}, {
	path: "/info/parameter/set06",
	name: "ParameterSet06",
	component: ParameterSet06,
}, {
	path: "/info/parameter/set07",
	name: "ParameterSet07",
	component: ParameterSet07,
}, {
	path: "/info/parameter/set08",
	name: "ParameterSet08",
	component: ParameterSet08,
}, {
	path: "/info/parameter/set09",
	name: "ParameterSet09",
	component: ParameterSet09,
}, {
	path: "/info/parameter/set10",
	name: "ParameterSet10",
	component: ParameterSet10,
}, {
	path: "/info/parameter/set11",
	name: "ParameterSet11",
	component: ParameterSet11,
}, {
	path: "/info/parameter/set12",
	name: "ParameterSet12",
	component: ParameterSet12,
}, {
	path: "/info/parameter/set13",
	name: "ParameterSet13",
	component: ParameterSet13,
}, {
	path: "/info/parameter/set14",
	name: "ParameterSet14",
	component: ParameterSet14,
}, {
	path: "/info/parameter/set15",
	name: "ParameterSet15",
	component: ParameterSet15,
}, {
	path: "/info/parameter/set16",
	name: "ParameterSet16",
	component: ParameterSet16,
}, {
	path: "/info/VoucherType",
	name: "VoucherType",
	component: VoucherType,
}, {
	path: "/weixin/parament/banner",
	name: "Parament01",
	component: Parament01,
}, {
	path: "/weixin/parament/serve",
	name: "Parament02",
	component: Parament02,
}, {
	path: "/weixin/parament/help",
	name: "Parament03",
	component: Parament03,
}, {
	path: "/weixin/parament/help/add",
	name: "HelpAdd",
	component: HelpAdd,
}, {
	path: "/weixin/parament/pack",
	name: "Parament04",
	component: Parament04,
}, {
	path: "/weixin/parament/consult/list",
	name: "ConsultList",
	component: ConsultList,
}, {
	path: "/weixin/parament/goods/list",
	name: "GoodsList",
	component: GoodsList,
}, {
	path: "/weixin/parament/goods/detail",
	name: "GoodsDetail",
	component: GoodsDetail,
}, {
	path: "/weixin/parament/about",
	name: "About",
	component: About,
}, {
	path: "/weixin/parament/user",
	name: "UserList",
	component: UserList,
}, {
	path: "/authority/administrator",
	name: "Administrator",
	component: Administrator,
}, {
	path: "/authority/administrator/add",
	name: "AdministratorAdd",
	component: AdministratorAdd,
}, {
	path: "/authority/role",
	name: "Role",
	component: Role,
}, {
	path: "/authority/role/add",
	name: "RoleAdd",
	component: RoleAdd,
}, {
	path: "/order/pdf",
	name: "orderPdf",
	component: orderPdf,
}, {
	path: "/offer/pdf",
	name: "offerPdf",
	component: offerPdf,
}, {
	path: "/settle/pdf",
	name: "settlePdf",
	component: settlePdf,
}, {
	path: "/settle/pay/pdf",
	name: "settlePayPdf",
	component: settlePayPdf,
}, {
	path: "/settle/pay/pdfView",
	name: "settlePayPdfView",
	component: collectPdf,
}, {
	path: "/settle/collect/pdf",
	name: "collectPdf",
	component: collectPdf,
},{
	path: "/settle/collect/list",
	name: "collectList",
	component: collectList,
	meta: {
		isUseCache: false, // 默认不缓存
		keepAlive: true,
	}
},{
	path: "/settle/collect/show",
	name: "collectShow",
	component: collectShow,
}, {
	path: "/mxy",
	name: "mxy",
	component: mxy,
}];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

export default router;
