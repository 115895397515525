<template>
	<div class="box01">
		<v-crumbs :crumbsType='26'></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>用户信息</p>
						</div>
						<div class="info_wrapper top hid_">
							<div class="info_wrapper_block block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">头像:</p>
									<div class="form_choose_content">
										<el-image style="width: 60px; height: 60px" :src="info.member.avatar_text" fit="cover"></el-image>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block block" style="margin-bottom: 13px !important;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">昵称:</p>
									<div class="form_choose_content">{{info.member.nickname}}</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">性别:</p>
									<div class="form_choose_content">{{info.member.sex_text}}</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">生日:</p>
									<div class="form_choose_content">{{info.member.birthday}}</div>
								</div>
							</div>
							</br>
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">手机号:</p>
									<div class="form_choose_content">{{info.member.mobile}}</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">邮箱:</p>
									<div class="form_choose_content">{{info.member.email}}</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">QQ:</p>
									<div class="form_choose_content">{{info.member.qq}}</div>
								</div>
							</div>
							<div class="info_wrapper_block block" style="margin-top: 13px !important;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">所在地:</p>
									<div class="form_choose_content">{{info.member.address}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>货物信息</p>
						</div>
						<div class="info_wrapper top hid_">
							<div class="info_wrapper_block block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">服务范围:</p>
									<div class="form_choose_content">{{info.services_scope}}</div>
								</div>
							</div>
							<div class="info_wrapper_block block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">运输方式:</p>
									<div class="form_choose_content">{{info.shipping_type}}</div>
								</div>
							</div>
							<div class="info_wrapper_block block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">运输流向:</p>
									<div class="form_choose_content">{{info.transportation}}</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="margin-top: 0 !important;margin-bottom: 26px !important;">
								<div class="form_choose_wrapper">
									<p class="form_input_label" style="margin-right: 20px;">起运地/起运港:</p>
									<div class="form_choose_content" style="width: 377px;">{{info.shipment_harbor.name}}/{{info.shipment_harbor.code}}</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="margin-top: 0 !important;margin-bottom: 26px !important;">
								<div class="form_choose_wrapper">
									<p class="form_input_label" style="margin-right: 20px;">目的地/目的港:</p>
									<div class="form_choose_content" style="width: 377px;">{{info.bourn_harbor.name}}/{{info.bourn_harbor.code}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block" v-for="(item, index) in info.lists" :key="index">
						<div class="form_wrapper_title">
							<p></p>
							<p>货物数据</p>
						</div>
						<div class="info_wrapper top hid_" style="padding-bottom: 13px;">
							<div class="info_wrapper_block block" style="margin-bottom: 13px !important;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">中文品名:</p>
									<div class="form_choose_content">{{item.name}}</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">件数:</p>
									<div class="form_choose_content">{{item.number}}</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">包装:</p>
									<div class="form_choose_content">{{item.pack.title}}</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">毛重:</p>
									<div class="form_choose_content">{{item.weight}} KG</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<!-- <div class="form_choose_wrapper">
									<p class="form_input_label">计重:</p>
									<div class="form_choose_content"></div>
								</div> -->
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">长:</p>
									<div class="form_choose_content">{{item.length}} CM</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">宽:</p>
									<div class="form_choose_content">{{item.width}} CM</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">高:</p>
									<div class="form_choose_content">{{item.tall}} CM</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="width: 23%;">
								<div class="form_choose_wrapper">
									<p class="form_input_label">体积:</p>
									<div class="form_choose_content">{{item.volume}} CBM</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block">
					<div class="form_right_add_list">
						<div class="p_lr15">
							<div class="form_input_wrapper">
								<p class="form_input_label">处理人：</p>
								<div class="form_input">
									<el-input placeholder="请填写处理人" v-model="submit_info.handler"></el-input>
								</div>
							</div>
							<div class="form_input_wrapper" style="align-items: flex-start;">
								<p class="form_input_label" style="margin-top: 10px;">备注：</p>
								<div class="form_input">
									<el-input type="textarea" placeholder="请填写发货人信息" v-model="submit_info.remark"></el-input>
								</div>
							</div>
						</div>
					</div>
					<div class="btn01" style="margin: 30px auto;" @click="get_sure">确认</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑

	import {
		cargoEnquiryList,
		cargoEnquiryShow,
		cargoEnquiryEdit,
		cargoEnquiryDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs
		},
		data() {
			return {
				id: '',
				info: {
					member: {
						avatar_text: ''
					},
					shipment_harbor: {},
					bourn_harbor: {}
				},
				submit_info: {
					handler: '', // 处理人
					remark: '', // 描述
				}
			}
		},
		created() {
			this.id = this.$route.query.id;
			this.get_detail();
		},
		methods: {
			// 详情
			get_detail() {
				cargoEnquiryShow({
					id: this.id
				}).then(res => {
					this.info = res.data;

					this.submit_info = {
						id: this.id,
						handler: this.info.handler ? this.info.handler : '',
						remark: this.info.remark ? this.info.remark : ''
					}
				})
			},
			// 确认
			get_sure() {
				if (!this.submit_info.handler) return this.$message('请输入处理人');
				if (!this.submit_info.remark) return this.$message('请输入备注');

				cargoEnquiryEdit(this.submit_info).then(res => {
					this.$message.success('成功');
					this.$router.go(-1);
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 0 9px;
	}

	.info_wrapper_block {
		margin-top: 13px !important;
		margin-bottom: 13px !important;
	}

	.info_wrapper_block.block {
		display: block;
		margin-top: 26px !important;
		margin-bottom: 26px !important;
	}

	.form_choose_wrapper {
		align-items: center;
	}

	.form_input_label {
		margin-top: 0 !important;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	.form_wrapper .form_input.w_266 {
		width: 266px;
	}

	.form_wrapper .form_wrapper_right {
		padding: 0 18px;

		.form_table_add {
			padding: 0 9px;
		}
	}

	.form_right_add_list {
		border-radius: 10px;
		margin: 15px 0;
		border: 1px solid #486AA5;
	}

	.form_choose_content {
		color: #fff;
		width: 202px;
		font-size: 14px;
		line-height: 20px;
	}

	.form_choose_content.square {
		.form_choose_block {
			&.active {
				i {
					background: url(../../assets/06@2x.png) no-repeat center;
					background-size: 100%;
				}
			}

			i {
				background: url(../../assets/05@2x.png) no-repeat center;
				background-size: 100%;
			}
		}
	}
</style>
