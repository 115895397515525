import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import css from "./public/css/public.css";
import 'element-ui/lib/theme-chalk/index.css';
import publicFun from './public/js/public.js';
import htmlToPdf from './public/js/htmlToPdf.js';

Vue.use(ElementUI);
Vue.use(htmlToPdf);

import {
	Message
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.prototype.$publicFun = publicFun;

Vue.config.productionTip = false;

document.title = publicFun.get_LostName().loginName;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
