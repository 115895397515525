<template>
	<div class="box01">
		<v-crumbs :crumbsType="4" @get_mxy="get_mxy"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="$publicFun.get_link('/order/add', {})">新增</div>
				<v-select02 :p_text="'订单编号/运单编号/销售人员'" :value_="formInfo.order_no" @serch="get_serch" @empty="get_empty">
				</v-select02>
				<div class="btn01 m_l10" @click="modal_show = true">高级搜索</div>
			</div>
			<div class="flex03">
				<div class="btn01" @click="get_ship_form">生成订舱单</div>
				<!-- <div class="btn01 m_l10" @click="get_derive">导出订舱单</div> -->
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="order_no" label="订单编号" width="160"></el-table-column>
					<el-table-column label="订单状态" width="100">
						<template slot-scope="scope">
							<div style="display: flex;height: 20px;align-items: center;">
								<span v-if="scope.row.operation_status === 0"
									style="display: block;margin: 0 auto;">null</span>
								<span class="dot dot1" v-if="scope.row.operation_status === 1"></span>
								<span class="dot dot2" v-if="scope.row.operation_status === 2"></span>
								<span class="dot dot3" v-if="scope.row.operation_status === 3"></span>
								<span class="dot dot4" v-if="scope.row.operation_status === 4"></span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="service_type.name" label="服务类型" width="160"></el-table-column>
					<el-table-column prop="services_scope.name" label="服务范围" width="160"></el-table-column>
					<el-table-column prop="waybill_no" label="运单编号" width="260"></el-table-column>
					<el-table-column prop="company.company_name" label="客户名称" width="320"></el-table-column>
					<el-table-column label="起运地/起运港" width="140">
						<template slot-scope="scope">
							{{scope.row.shipment.name}}/{{scope.row.shipment.code}}
						</template>
					</el-table-column>
					<el-table-column label="目的地/目的港" width="140">
						<template slot-scope="scope">
							{{scope.row.destination.name}}/{{scope.row.destination.code}}
						</template>
					</el-table-column>
					<el-table-column prop="bidder" label="负责人" width="90"></el-table-column>
					<el-table-column prop="createtime_text" label="添加时间" width="200"></el-table-column>
					<el-table-column prop="total_price" label="收入合计" width="200"></el-table-column>
					<el-table-column prop="cost_total_price" label="成本合计" width="200"></el-table-column>

					<el-table-column label="财务批注" width="200">
						<template slot-scope="scope">
							<span :class="{del:scope.row.approval_list.apply_status == 1}" class="text-del"
								v-if="scope.row.approval_list">{{scope.row.approval_list.remark}}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="260">
						<template slot-scope="scope">
							<div class="flex04">
								<!-- {{operation_status}} -->
								<p class="m_r10 pointer_"
									@click="$publicFun.get_link('/order/edit?id=' + scope.row.id, {})">成本编辑</p>
								<!-- <p class="c_6d9fff m_r10 pointer_">查看</p> -->
								<p class="c_6d9fff m_r10 pointer_"
									@click="$publicFun.get_link('/order/edit?type=1&id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f pointer_ m_r10" @click="get_del(scope.row)">删除</p>
								<span class="cwBtn"
									v-if="!scope.row.approval_list || (scope.row.approval_list && scope.row.approval_list.apply_status === 1)"
									@click="getFinanceData(scope.row)">财务批注</span>
								<span class="cwBtn"
									v-if="scope.row.approval_list && scope.row.approval_list.apply_status === 0"
									@click="financeAgain(scope.row)">已处理</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<!-- <div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page"
				layout="prev, pager, next, jumper" :total="total" @current-change="currentChange"></el-pagination>
		</div> -->
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page"
				layout="prev, pager, next, jumper, sizes" :total="total"
				:page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange"
				@size-change="handleSizeChange"></el-pagination>
		</div>
		<!-- 高级搜索 -->
		<v-screen :modal_type="1" :modal_show="modal_show" @getExit="getExit" @getInquire="getInquire"></v-screen>
		<!-- 财务批注弹窗 -->
		<v-finance :dialogVisible="financeShow" :financeData="financeData" @getModalClose="financeClose"
			@getSubmit="getSubmit"></v-finance>
	</div>
</template>

<script>
	import crumbs from '@/components/crumbs/index.vue'; // 面包屑
	import select02 from '@/components/select/index01.vue'; // 搜索框
	import screen from '@/components/screen/index.vue'; // 高级搜索
	import finance from '@/components/finance/index.vue'; // 高级搜索

	import {
		orderList,
		orderDel,
		pushWarehouseReceipt,
		updateStatusApi,
		financeAgainFun,
		getModalCloseFun,
		getSubmitFun,
		getFinanceDataFun
	} from '@/api/index.js';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-screen': screen,
			'v-finance': finance,
			'v-select02': select02
		},
		data() {
			return {
				financeShow: false,
				financeData: {
					type_id: 1, //1-订单 2-应收 3-应付
					source_id: "",
					remark: "",
					op_type: 0, //0-输入,1-勾选
				},
				financeIdx: null,

				list: [],
				total: null,
				detail_info: {},
				choose_id: [],
				modal_show: false, // 高级搜索
				formInfo: {
					page: 1,
					limit: 10,
					order_no: '', // 订单编号
					start_time: '', // 开始日期
					end_time: '', // 结束日期
					service_type_id: '', // 服务类型
					bidder: '', // 报价人员
					company_id: '', // 客户名称
					business_id: '', // 供应商名称
					shipment_id: '', // 起运地/起运港
					destination_id: '', // 目的地/目的港
				},
			}
		},
		created() {
			this.getOrderList()
		},
		methods: {
			// 已处理
			financeAgain(e) {
				financeAgainFun(this, e)
			},
			//关闭
			financeClose(e) {
				getModalCloseFun(this, e)
			},
			//提交
			getSubmit(e) {
				getSubmitFun(this, e)
			},
			// 财务批注
			getFinanceData(e) {
				getFinanceDataFun(this, e)
			},
			get_mxy() {
				this.formInfo = {
					page: 1,
					limit: 10,
					order_no: '', // 订单编号
					start_time: '', // 开始日期
					end_time: '', // 结束日期
					service_type_id: '', // 服务类型
					bidder: '', // 报价人员
					company_id: '', // 客户名称
					business_id: '', // 供应商名称
					shipment_id: '', // 起运地/起运港
					destination_id: '', // 目的地/目的港
				}

				this.getOrderList();
			},
			// 导出
			get_derive() {

			},
			// 获取列表
			getOrderList() {
				orderList(this.formInfo).then(res => {
					res.data.data.forEach((n, k) => {
						n.fuzeren = '-'
					})

					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.getOrderList();
				window.scroll(0, 0);
			},
			// 点击一页请求数据
			handleSizeChange(num) {
				this.formInfo.page = 1;
				this.formInfo.limit = num;
				this.getOrderList();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入订单编号');

				this.formInfo.page = 1;
				this.formInfo.order_no = e // 订单编号
				this.formInfo.start_time = '' // 报价开始日期
				this.formInfo.end_time = '' // 报价结束日期
				this.formInfo.service_type_id = '' // 服务类型
				this.formInfo.bidder = '' // 报价人员
				this.formInfo.company_id = '' // 客户名称
				this.formInfo.business_id = '' // 供应商名称
				this.formInfo.shipment_id = '' // 起运地/起运港
				this.formInfo.destination_id = '' // 目的地/目的港

				this.getOrderList();
			},
			// 清空搜索
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.order_no = '' // 订单编号
				this.formInfo.start_time = '' // 报价开始日期
				this.formInfo.end_time = '' // 报价结束日期
				this.formInfo.service_type_id = '' // 服务类型
				this.formInfo.bidder = '' // 报价人员
				this.formInfo.company_id = '' // 客户名称
				this.formInfo.business_id = '' // 供应商名称
				this.formInfo.shipment_id = '' // 起运地/起运港
				this.formInfo.destination_id = '' // 目的地/目的港

				this.getOrderList();
			},
			// 高级搜索
			getInquire(e) {
				this.formInfo.page = 1;
				this.formInfo.order_no = '' // 订单编号
				this.formInfo.start_time = e.start_time // 报价开始日期
				this.formInfo.end_time = e.end_time // 报价结束日期
				this.formInfo.service_type_id = e.service_type_id // 服务类型
				this.formInfo.bidder = e.bidder // 报价人员
				this.formInfo.company_id = e.company_id // 客户名称
				this.formInfo.business_id = e.business_id
				this.formInfo.shipment_id = e.shipment_id // 起运地/起运港
				this.formInfo.destination_id = e.destination_id // 目的地/目的港

				this.getOrderList();
			},
			getExit() {
				this.modal_show = false;
			},
			// 生成订舱单
			get_ship_form() {
				if (this.choose_id.length === 0) return this.$message('请至少选中一个订单');

				pushWarehouseReceipt({
					ids: this.choose_id.join(',')
				}).then(res => {
					this.$message.success('转入成功');
					this.getOrderList();
				})
			},
			// 选中id
			handleSelectionChange(e) {
				this.choose_id = [];

				if (e.length !== 0) {
					e.forEach((n, k) => {
						this.choose_id.push(n.id)
					})
				}
			},
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					orderDel({
						id: e.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.getOrderList();
						this.$message.success('删除成功');
					})
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}

	.dot {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		display: block;
		margin: 0 auto
	}

	.dot1 {
		background-color: #B8B8B8;
	}

	.dot2 {
		background-color: #FFBD36;
	}

	.dot3 {
		background-color: #F87679;
	}

	.dot4 {
		background-color: #8AC43A;
	}

	// .cwBtn:hover{text-decoration: underline;}
</style>
