<template>
	<div class="wx-modal_wrapper">
		<el-dialog title="" custom-class="dialog_wrapper" :visible.sync="dialogVisible" :show-close="false" width="26%">
			<div class="wx-modal_top">
				<p class="font_16">{{type == 3 || type == 6 || type == 7?'新增': type == 4 || type == 5?'查看': '上传'}}</p>
				<i @click="get_close"></i>
			</div>
			<div style="padding: 0 34px;">
				<div class="hid_" v-if="type == 1">
					<div class="flex01 m_t20">
						<div class="font_12 w_70">标题：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入标题" v-model="detail.title" clearable></el-input>
						</div>
					</div>
					<div class="wx-up_image flex03">
						<div class="font_12 w_70 flex01" style="height: 60px;">轮播图：</div>
						<div>
							<el-upload class="avatar-uploader" :action="up_url" :show-file-list="false" :on-success="handleAvatarSuccess"
							 :before-upload="beforeAvatarUpload">
								<el-image style="width: 120px; height: 80px" :src="detail.url_text" fit="cover" class="avatar" v-if="detail.url_text"></el-image>
								<i class="up_icon" v-else></i>
							</el-upload>
							<p class="font_12 c_ff6f6f m_t6">375*211比例大小图片</p>
						</div>
					</div>
					<div class="btn01 m01" @click="get_save">保存</div>
				</div>
				<div class="hid_" v-if="type == 2">
					<div class="flex01 m_t20">
						<div class="font_12 w_70">名称：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入名称" v-model="detail.title" clearable></el-input>
						</div>
					</div>
					<div class="wx-up_image flex03">
						<div class="font_12 w_70 flex01" style="height: 60px;">主图：</div>
						<div>
							<el-upload class="avatar-uploader" :action="up_url" :show-file-list="false" :on-success="handleAvatarSuccess"
							 :before-upload="beforeAvatarUpload">
								<el-image style="width: 60px; height: 60px" :src="detail.url_text" fit="cover" class="avatar" v-if="detail.url_text"></el-image>
								<i class="up_icon" v-else></i>
							</el-upload>
							<p class="font_12 c_ff6f6f m_t6">50*50比例大小图片</p>
						</div>
					</div>
					<div class="btn01 m01" @click="get_save">保存</div>
				</div>
				<div class="hid_" v-if="type == 3">
					<div class="flex01 m_t20">
						<div class="font_12 w_70">包装名称：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入包装名称" v-model="detail.title" clearable></el-input>
						</div>
					</div>
					<div class="btn01 m01" @click="get_save">保存</div>
				</div>
				<div class="hid_" v-if="type == 4">
					<div class="flex03 m_t20">
						<div class="font_12 w_70" style="line-height: 20px;">类别：</div>
						<div class="wx-modal_input">
							<p class="font_14">{{detail.service_id?detail.service_type.title: '首页'}}</p>
						</div>
					</div>
					<div class="flex03 m_t20">
						<div class="font_12 w_70" style="line-height: 20px;">联系人：</div>
						<div class="wx-modal_input">
							<p class="font_14">{{detail.username}}</p>
						</div>
					</div>
					<div class="flex03 m_t20">
						<div class="font_12 w_70" style="line-height: 20px;">联系方式：</div>
						<div class="wx-modal_input">
							<p class="font_14">{{detail.mobile}}</p>
						</div>
					</div>
					<div class="flex03 m_t20">
						<div class="font_12 w_70" style="line-height: 20px;">需求：</div>
						<div class="wx-modal_input">
							<p class="font_14">{{detail.remark}}</p>
						</div>
					</div>
					<div class="flex01 m_t20">
						<div class="font_12 w_70">处理人：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入处理人" v-model="detail.handler" clearable></el-input>
						</div>
					</div>
					<div class="m_t20">
						<div class="font_12 w_70 m_b16">备注：</div>
						<div class="wx-modal_input">
							<el-input type="textarea" :rows="5" placeholder="请输入备注" v-model="detail.admin_remark" clearable></el-input>
						</div>
					</div>
					<div class="btn01 m02" @click="get_save">保存</div>
				</div>
				<div class="hid_" v-if="type == 5">
					<div class="wrapper">
						<div class="flex01 m_t20">
							<div class="font_12 w_70" style="line-height: 20px;">头像：</div>
							<div class="wx-modal_input">
								<div class="i">
									<el-image style="width: 45px; height: 45px" :src="detail.avatar_text" fit="cover"></el-image>
								</div>
							</div>
						</div>
					</div>
					<div class="wrapper">
						<div class="flex01 m_t20" style="height: 45px;">
							<div class="font_12 w_70" style="line-height: 20px;">昵称：</div>
							<div class="wx-modal_input">
								<p class="font_14">{{detail.nickname}}</p>
							</div>
						</div>
					</div>
					<div class="wrapper">
						<div class="flex03 m_t20">
							<div class="font_12 w_70" style="line-height: 20px;">性别：</div>
							<div class="wx-modal_input">
								<p class="font_14">{{detail.sex_text}}</p>
							</div>
						</div>
					</div>
					<div class="wrapper">
						<div class="flex03 m_t20">
							<div class="font_12 w_70" style="line-height: 20px;">生日：</div>
							<div class="wx-modal_input">
								<p class="font_14">{{detail.birthday?detail.birthday: '-'}}</p>
							</div>
						</div>
					</div>
					<div class="wrapper">
						<div class="flex03 m_t20">
							<div class="font_12 w_70" style="line-height: 20px;">手机号：</div>
							<div class="wx-modal_input">
								<p class="font_14">{{detail.mobile?detail.mobile: '-'}}</p>
							</div>
						</div>
					</div>
					<div class="wrapper">
						<div class="flex03 m_t20">
							<div class="font_12 w_70" style="line-height: 20px;">邮箱：</div>
							<div class="wx-modal_input">
								<p class="font_14">{{detail.email?detail.email: '-'}}</p>
							</div>
						</div>
					</div>
					<div class="wrapper">
						<div class="flex03 m_t20">
							<div class="font_12 w_70" style="line-height: 20px;">QQ：</div>
							<div class="wx-modal_input">
								<p class="font_14">{{detail.qq?detail.qq: '-'}}</p>
							</div>
						</div>
					</div>
					<div class="flex03 m_t20">
						<div class="font_12 w_70" style="line-height: 20px;">所在地：</div>
						<div class="wx-modal_input">
							<p class="font_14">{{detail.address?detail.address: '-'}}</p>
						</div>
					</div>
					<div class="flex03 m_t20">
						<div class="font_12 w_70" style="line-height: 20px;">添加时间：</div>
						<div class="wx-modal_input">
							<p class="font_14">{{detail.createtime_text}}</p>
						</div>
					</div>
					<div class="btn01 m02" @click="get_close">确认</div>
				</div>
				<div class="hid_" v-if="type == 6">
					<div class="flex01 m_t20">
						<div class="font_12 w_70">中文名称：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入中文名称" v-model="input" clearable></el-input>
						</div>
					</div>
					<div class="flex01 m_t20">
						<div class="font_12 w_70">英文名称：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入英文名称" v-model="input" clearable></el-input>
						</div>
					</div>
					<div class="btn01 m01">保存</div>
				</div>
				<div class="hid_" v-if="type == 7">
					<div class="flex01 m_t20">
						<div class="font_12 w_70">模板名称：</div>
						<div class="wx-modal_input">
							<el-input placeholder="请输入模板名称" v-model="detail.name" clearable></el-input>
						</div>
					</div>
					<div class="btn01 m01" @click="get_save">保存</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			dialogVisible: {
				type: Boolean
			},
			type: {
				type: Number
			},
			detail: {
				type: Object
			}
		},
		data() {
			return {
				up_url: '', // 上传图片地址
			}
		},
		created() {
			this.up_url = this.$publicFun.get_up_img();
		},
		methods: {
			beforeAvatarUpload() {

			},
			handleAvatarSuccess(e) {
				this.detail.url = e.data;
				this.detail.url_text = this.$publicFun.get_host() + e.data;

				this.$forceUpdate();
			},
			// 关闭
			get_close() {
				this.$emit('getModalClose', false);
			},
			// 保存
			get_save() {
				if (this.type == 1) {
					if (!this.detail.title) return this.$message('请输入标题');
					if (!this.detail.url) return this.$message('请上传图片');
				}

				if (this.type == 2) {
					if (!this.detail.title) return this.$message('请输入名称');
					if (!this.detail.url) return this.$message('请上传图片');
				}

				if (this.type == 3) {
					if (!this.detail.title) return this.$message('请输入包装名称');
				}

				if (this.type == 4) {
					if (!this.detail.handler) return this.$message('请输入处理人');
					if (!this.detail.admin_remark) return this.$message('请输入备注');
				}

				if (this.type == 7) {
					if (!this.detail.name) return this.$message('请输入模板名称');
				}

				this.$emit('get_data_amend', this.detail);
			}
		}
	}
</script>

<style lang="less">
	.m01 {
		margin: 228px auto 26px auto;
	}

	.m02 {
		margin: 42px auto 26px auto;
	}

	.wrapper {
		width: 50%;
		display: inline-block;
		vertical-align: top;
	}

	.dialog_wrapper {
		border-radius: 8px !important;

		.el-dialog__header {
			padding: 0;
		}

		.el-dialog__body {
			padding: 0;
		}

		.el-upload {
			display: block;
		}

		.i .el-image {
			margin: 0;
		}

		.wx-modal_input {
			.el-input__inner {
				border-radius: 10px;
				border-color: #D2D2D2;

				&:focus {
					border-color: #D2D2D2;
				}
			}

			.el-textarea__inner {
				border-radius: 10px;
				border-color: #D2D2D2;

				&:focus {
					border-color: #D2D2D2;
				}
			}
		}
	}

	.wx-modal_top {
		height: 60px;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #D0D0D0;

		i {
			top: 17px;
			right: 20px;
			display: block;
			width: 26px;
			height: 26px;
			cursor: pointer;
			position: absolute;
			background: url(../../assets/wx/05@2x.png) no-repeat center;
			background-size: 100% 100%;
		}
	}

	.wx-up_image {
		margin-top: 23px;
	}

	.wx-modal_input {
		flex: 1;
	}

	.up_icon {
		display: block;
		width: 60px;
		height: 60px;
		background: url(../../assets/wx/06@2x.png) no-repeat center;
		background-size: 100% 100%;
	}
</style>
