<!-- 头部面包屑 -->
<template>
	<div class="crumbs_box flex01">
		<img src="./image/01@2x.png" />
		<p class="font_14 c_5bbef0" v-if="crumbsType == 0">您当前所在位置：报价管理 》<span class="" @click="$emit('')">报价查询</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 1">您当前所在位置：报价管理 》<span class="pointer_" @click="$router.go(-1)">报价查询</span>
			》新增报价</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 2">您当前所在位置：报价管理 》<span class="pointer_" @click="$router.go(-1)">报价查询</span>
			》成本编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 222">您当前所在位置：报价管理 》<span class="pointer_" @click="$router.go(-1)">报价查询</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 3">您当前所在位置：报价管理 》报价模板</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 333">您当前所在位置：报价管理 》<span class="pointer_" @click="$router.go(-1)">报价模板</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 4">您当前所在位置：订单管理 》<span class="" @click="$emit('')">订单列表</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 5">您当前所在位置：订单管理 》<span class="pointer_" @click="$router.go(-1)">订单查询</span>
			》新增订单</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 6">您当前所在位置：订单管理 》<span class="pointer_" @click="$router.go(-1)">订单查询</span>
			》成本编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 666">您当前所在位置：订单管理 》<span class="pointer_" @click="$router.go(-1)">订单查询</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 7">您当前所在位置：订单管理 》订舱单查询</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 8">您当前所在位置：订单管理 》<span class="pointer_" @click="$router.go(-1)">订舱单查询</span>
			》新增订舱单</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 80">您当前所在位置：订单管理 》<span class="pointer_" @click="$router.go(-1)">订舱单查询</span>
			》查看订舱单</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 81">您当前所在位置：订单管理 》<span class="pointer_" @click="$router.go(-1)">订舱单查询</span>
			》编辑订舱单</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 9">您当前所在位置：结算管理 》<span class="" @click="$emit('')">应收结算列表</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 10">您当前所在位置：结算管理 》<span class="pointer_" @click="$router.go(-1)">应收结算列表</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 11">您当前所在位置：结算管理 》<span class="" @click="$emit('')">应付结算列表</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 12">您当前所在位置：结算管理 》<span class="pointer_" @click="$router.go(-1)">应付结算列表</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 13">您当前所在位置：结算管理 》<span class="" @click="$emit('')">应收完成列表</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 111">您当前所在位置：结算管理 》<span class="pointer_" @click="$router.go(-1)">应收完成列表</span>
			》查看</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 130">您当前所在位置：结算管理 》<span class="" @click="$emit('')">应付完成列表</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 122">您当前所在位置：结算管理 》<span class="pointer_" @click="$router.go(-1)">应付完成列表</span>
			》查看</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 190">您当前所在位置：结算管理 》<span class="" @click="$emit('')">汇总账单列表</span></p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 191">您当前所在位置：结算管理 》<span class="pointer_" @click="$router.go(-1)">汇总账单列表</span>
			》详情</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 14">您当前所在位置：报表管理 》业务数据</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 15">您当前所在位置：报表管理 》财务数据</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 16">您当前所在位置：基础信息 》企业管理</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 17">您当前所在位置：基础信息 》<span class="pointer_" @click="$router.go(-1)">企业管理</span>
			》新增</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 170">您当前所在位置：基础信息 》<span class="pointer_" @click="$router.go(-1)">企业管理</span>
			》查看</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 171">您当前所在位置：基础信息 》<span class="pointer_" @click="$router.go(-1)">企业管理</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 18">您当前所在位置：基础信息 》参数设置</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 19">您当前所在位置：小程序管理 》参数配置 》轮播图列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 20">您当前所在位置：小程序管理 》参数配置 》服务列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 21">您当前所在位置：小程序管理 》参数配置 》帮助中心</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 22">您当前所在位置：小程序管理 》参数配置 》<span class="pointer_" @click="$router.go(-1)">帮助中心</span>
			》新增</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 221">您当前所在位置：小程序管理 》参数配置 》<span class="pointer_" @click="$router.go(-1)">帮助中心</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 23">您当前所在位置：小程序管理 》参数配置 》包装列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 24">您当前所在位置：小程序管理 》立即咨询列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 25">您当前所在位置：小程序管理 》货物询价列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 26">您当前所在位置：小程序管理 》<span class="pointer_" @click="$router.go(-1)">货物询价列表</span>
			》详情</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 27">您当前所在位置：小程序管理 》关于我们</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 28">您当前所在位置：小程序管理 》用户列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 29">您当前所在位置：权限管理 》管理员列表</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 30">您当前所在位置：权限管理 》角色组</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 31">您当前所在位置：权限管理 》<span class="pointer_" @click="$router.go(-1)">角色组</span>
			》新增</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 311">您当前所在位置：权限管理 》<span class="pointer_" @click="$router.go(-1)">角色组</span>
			》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 32">您当前所在位置：权限管理 》<span class="pointer_" @click="$router.go(-1)">管理员列表</span>
			》新增</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 322">您当前所在位置：权限管理 》<span class="pointer_" @click="$router.go(-1)">管理员列表</span> 》编辑</p>
		<p class="font_14 c_5bbef0" v-if="crumbsType == 70">您当前所在位置：基础信息 》凭证类型</p>
	</div>
</template>

<script>
	export default {
		props: {
			crumbsType: {
				type: Number
			},
			back: {
				type: Boolean
			}
		},
		data() {
			return {

			}
		}
	}
</script>

<style lang="less" scoped>
	.crumbs_box {
		margin: 24px 0 19px;

		img {
			width: 15px;
			margin-right: 8px;
		}
	}
</style>
