<template>
	<div>
		<el-upload class="avatar-uploader" :action="up_url" :show-file-list="false" :on-success="handleAvatarSuccess"
		 :before-upload="beforeAvatarUpload">
			<div class="btn01 m_r10">导入</div>
		</el-upload>
	</div>
</template>

<script>
	import {
		importStartHarbor,
		importEndHarbor,
		importCostName,
		importInternCity,
		importAviationCompan,
		importShipCompany,
		importHsCode
	} from '@/api/index';

	export default {
		props: {
			type: {
				type: Number
			}
		},
		data() {
			return {
				up_url: '', // 上传地址
			}
		},
		created() {
			this.up_url = this.$publicFun.get_up_img();
		},
		methods: {
			handleAvatarSuccess(e) {
				if (this.type === 1) {
					// 起运地
					importStartHarbor({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}

				if (this.type === 2) {
					// 目的地
					importEndHarbor({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}

				if (this.type === 3) {
					// 费用名称
					importCostName({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}

				if (this.type === 4) {
					// 国际城市
					importInternCity({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}

				if (this.type === 5) {
					// 航空公司
					importAviationCompan({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}

				if (this.type === 6) {
					importShipCompany({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}
				
				if(this.type === 7) {
					// hs编码
					importHsCode({
						file: e.data
					}).then(res => {
						this.$message.success('导入成功！');
						this.$emit('get_daoru_success')
					})
				}
			},
			beforeAvatarUpload(e) {

			}
		}
	}
</script>

<style>
</style>
