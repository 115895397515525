<template>
	<div class="box01">
		<v-crumbs :crumbsType='crumbs_num'></v-crumbs>
		<div style="position: relative;">
			<div class="shade_wrapper" v-if="id && !bj"></div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>基本信息</p>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">订舱单编号:</p>
									<div class="form_input">
										<el-input v-model="submit_info.receipt_order_no" placeholder="保存时自动生成" :readonly="true"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">订单编号:</p>
									<div class="form_input">
										<el-input v-model="submit_info.order_no" placeholder="获取转入或保存时生成" :readonly="true"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label"><span class="c_ff6f6f">*</span>船名/航次:</p>
									<div class="form_input">
										<el-input v-model="submit_info.vessel_voyage" placeholder="请填写船名/航次"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">航司/船司:</p>
									<div class="form_input">
										<v-select :list="company_list" :value_name="submit_info.shipping_company_name" :from="1" @result="get_ship"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118">供应商名称:</p>
									<div class="form_input w_480">
										<v-select :icon_num="true" :list="supplier_list" :value_name="submit_info.company_id_name" @result="get_supplier"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">运单编号:</p>
									<div class="form_input w_510">
										<el-input v-model="submit_info.waybill_no" placeholder="请填写运单编号"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118"><span class="c_ff6f6f">*</span>起运地/起运港:</p>
									<div class="form_input w_480">
										<!-- <v-select :icon_num="true" :list="start_harbor_list" :value_name="submit_info.shipment_id_name" :from="2"
										 :service_type="submit_info.service_type_id" @result="get_harbor"></v-select> -->
										<v-select :icon_num="true" :list="start_harbor_list" :value_name="submit_info.shipment_id_name" :from="2"
										 :service_type="51" @result="get_harbor"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label w_118"><span class="c_ff6f6f">*</span>目的地/目的港:</p>
									<div class="form_input w_480">
										<!-- <v-select :icon_num="true" :list="end_harbor_list" :value_name="submit_info.destination_id_name" :from="3"
										 :service_type="submit_info.service_type_id" @result="get_end_harbor"></v-select> -->
										<v-select :icon_num="true" :list="end_harbor_list" :value_name="submit_info.destination_id_name" :from="3"
										 :service_type="51" @result="get_end_harbor"></v-select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>货物信息</p>
						</div>
						<div class="form_table_wrapper">
							<div class="form_table_add">
								<div class="form_table_add_block">
									<img src="../../assets/04@2x.png" @click="get_add_huowu" />
									<p>新增一组</p>
								</div>
							</div>
							<div>
								<div class="form_table_list" v-for="(item, index) in huowu_list" :key="index">
									<div class="form_delete_icon">
										<i @click="get_del_huowu(index, item)"></i>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>件数:</p>
											<div class="form_input">
												<el-input v-model="item.cases" placeholder="请填写件数"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>包装:</p>
											<div class="form_input">
												<v-select :list="pack_list" :index="index" :value_name="item.pack" @result="get_pak"></v-select>
												<!-- <el-input v-model="item.pack" placeholder="请填写包装"></el-input> -->
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>毛重:</p>
											<div class="form_input">
												<el-input v-model="item.rough_weight" placeholder="请填写毛重"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>计重:</p>
											<div class="form_input" style="display: flex;align-items: center;">
												<el-input v-model="item.weight" placeholder="自动算出"></el-input>
												<p class="form_input_unit">KG</p>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>长:</p>
											<div class="form_input">
												<el-input v-model="item.long" placeholder="请填写长度"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>宽:</p>
											<div class="form_input">
												<el-input v-model="item.broad" placeholder="请填写宽度"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>高:</p>
											<div class="form_input">
												<el-input v-model="item.tall" placeholder="请填写高度"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper dis">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>体积:</p>
											<div class="form_input" style="display: flex;align-items: center;">
												<el-input v-model="item.bulk" placeholder="自动算出"></el-input>
												<p class="form_input_unit">CBM</p>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>中文品名:</p>
											<div class="form_input">
												<el-input v-model="item.chinese_name" placeholder="请填写中文品名"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">英文品名:</p>
											<div class="form_input w_476">
												<el-input v-model="item.english_name" placeholder="请填写英文品名"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>HS编码:</p>
											<div class="form_input">
												<v-select :list="hs_list" :index="index" :value_name="item.hs_code_name" :from="4" @result="get_hs"></v-select>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>货值:</p>
											<div class="form_input">
												<el-input v-model="item.low_value" placeholder="请填写货值"></el-input>
											</div>
										</div>
									</div>
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f"></span>币制:</p>
											<div class="form_input">
												<v-select :list="currency_list" :index="index" :value_name="item.currency_system_name" @result="get_currency"></v-select>
											</div>
										</div>
									</div>
									<!-- <div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label">船名/航次:</p>
											<div class="form_input">
												<el-input v-model="item.flight_name" placeholder="请填写船名/航次"></el-input>
											</div>
										</div>
									</div> -->
									<div class="info_wrapper_block">
										<div class="form_input_wrapper">
											<p class="form_input_label"><span class="c_ff6f6f">*</span>起运时间:</p>
											<div class="form_input">
												<el-date-picker v-model="item.shipment_date" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block order">
					<div class="p_lr30">
						<div class="form_wrapper_info">
							<p class="form_wrapper_info_title"><span class="c_ff6f6f">*</span>联系人：</p>
							<!-- <div class="form_input w_100">
								<v-select :icon_num="true" :list="lxr_list" :index="1" :value_name="lxr_name" @result="get_kehu_serch"></v-select>
							</div> -->
							<div class="form_input w_100">
								<el-input v-model="submit_info.linkman_id_name" placeholder="请填写联系人"></el-input>
							</div>
						</div>
						<div class="form_wrapper_info">
							<p class="form_wrapper_info_title"><span class="c_ff6f6f">*</span>联系电话：</p>
							<div class="form_input w_100">
								<el-input v-model="submit_info.mobile" placeholder="请填写联系电话"></el-input>
							</div>
						</div>
						<div class="form_wrapper_info">
							<p class="form_wrapper_info_title">联系邮箱：</p>
							<div class="form_input w_100">
								<el-input v-model="submit_info.email" placeholder="请填写联系邮箱"></el-input>
							</div>
						</div>
						<div class="form_wrapper_info">
							<p class="form_wrapper_info_title">备注:</p>
							<div>
								<el-input v-model="submit_info.remark" type="textarea" placeholder="请填写备注"></el-input>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form_wrapper" v-if="!id || bj">
				<div class="form_wrapper_block" style="width: 100%;">
					<div class="form_wrapper_btn_wrapper">
						<div class="btn01" @click="addSubmit">保存</div>
						<div class="btn01" v-if="id" @click="$publicFun.get_link('/order/pdf?id=' + id)">预览</div>
						<div class="btn01" v-if="id" @click="$publicFun.get_link('/order/pdf?id=' + id)">导出</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select05 from '../../components/select/index05.vue'; // 模糊查询

	import {
		hsCode,
		currency,
		companyList,
		startHarbor,
		endHarbor,
		addDcList,
		aviationCompan,
		shipCompany,
		getAllList,
		dcAdd,
		dcDetail,
		dcEdit,
		dcDelCagro,
		getAllLinkman,
		packing,
		userLogin
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select': select05
		},
		data() {
			return {
				id: '',
				bj: false,
				lxr_id: '',
				lxr_name: '',
				lxr_list: [],
				pack_list: [],
				hs_list: [], // hs编码
				currency_list: [], // 币制
				company_list: [], // 航司/船司
				supplier_list: [], // 供应商
				end_harbor_list: [], // 目的地/目的港
				start_harbor_list: [], // 起运地/起运港
				crumbs_num: 8, // 8新增 80查看 81编辑					
				submit_info: {
					receipt_order_no: '', // 定舱单编号
					order_no: '', // 订单编号
					vessel_voyage: '', // 船名/航次
					shipping_company: '', // 航司/船司
					shipping_company_name: '',
					company_id: '', // 供应商名称
					company_id_name: '',
					shipment_id: '', // 起运地/起运港
					shipment_id_name: '',
					destination_id: '', // 目的地/目的港
					destination_id_name: '',
					mobile: '', // 联系电话
					email: '', // 联系邮箱
					remark: '',
					cargo: [], // 货物信息
				},
				huowu_list: [{
					cases: '', // 件数
					pack: '', // 包装
					rough_weight: '', // 毛重
					weight: '', // 计重
					long: '', // 长
					tall: '', // 高
					broad: '', // 宽
					bulk: '', // 体积
					chinese_name: '', // 中文品名
					english_name: '', // 英文品名
					hs_code: '', // HS编码
					hs_code_name: '',
					low_value: '', // 货值
					currency_system: '', // 币制
					currency_system_name: '',
					flight_name: '', // 船名/航次
					shipment_date: '' // 起运日期
				}], // 货物信息
			}
		},
		created() {
			setTimeout(() => {
				userLogin().then(res => {
					if (!this.submit_info.linkman_id_name) {
						this.submit_info.linkman_id_name = res.data.nickname;
					}

					if (!this.submit_info.mobile) {
						this.submit_info.mobile = res.data.mobile;
					}
				})
			}, 200)

			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.get_detail();
			}

			if (this.$route.query.bj) {
				this.bj = true
			}

			if (this.id && !this.bj) {
				this.crumbs_num = 80;
			}

			if (this.id && this.bj) {
				this.crumbs_num = 81;
			}

			// this.getHsCodeList();
			// this.get_getAllList();
			this.getCurrencyList();
			this.getSupplierList();
			// this.getEndHarborList();
			// this.getStartHarborList();
			this.getPackingList();
		},
		watch: {
			huowu_list: {
				deep: true,
				handler(val) {
					// 体积重量（kg）= 件数*长*宽*高/6000;
					// 体积（cbm）= 体积重量/167;
					// 计重 = 毛重or体积重量，取最大值;

					// 体积 = 长*宽*高*件数/100、0000；
					// 体积重量公式：体积重量=体积*167;
					let list_ = val;

					list_.forEach((n, k) => {
						if (n.long && n.broad && n.tall && n.cases) {
							n.tj = Number(n.cases) * Number(n.long) * Number(n.broad) * Number(n.tall) / 1000000; // 体积
							setTimeout(() => {
								n.bulk = Math.round(n.tj * 100) / 100;
							}, 300)
						} else {
							n.tj = '';
							n.bulk = '';
						}

						if (n.tj) {
							n.tj_zl = Number(n.tj) * 167;
						} else {
							n.tj_zl = '';
						}

						if (n.rough_weight && n.tj_zl) {
							n.jz = Number(n.rough_weight) > Number(n.tj_zl) ? n.rough_weight : n.tj_zl;
							
							// n.weight = Math.round(n.jz * 10) / 10;
							
							if (!n.bulk) {
								n.weight = Math.round(n.jz * 100) / 100;
							}
						} else {
							n.jz = '';
							// n.weight = '';
						}
					})
				}
			}
		},
		methods: {
			// 包装列表
			getPackingList() {
				packing({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.pack_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_pak(e) {
				this.huowu_list[e.index].pack = e.label;
			},
			// 详情数据
			get_detail() {
				dcDetail({
					id: this.id
				}).then(res => {
					this.submit_info = res.data;

					// this.lxr_name = res.data.linkman_id_name;
					// this.lxr_id = res.data.linkman_id;

					this.huowu_list = res.data.info;

					// this.get_linkman_list();
				})
			},
			// 获取船司/航司
			get_getAllList() {
				getAllList({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data;

					list_.forEach((n, k) => {
						this.company_list.push({
							label: n.ship_name,
							value: n.id
						})
					})
				})
			},
			get_ship(e) {
				this.submit_info.shipping_company = e.value;
				this.submit_info.shipping_company_name = e.label;
			},
			// 获取供应商列表
			getSupplierList() {
				companyList({
					page: 1,
					limit: 100000,
					partner_type: 1
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.supplier_list.push({
							label: n.company_name,
							value: n.id
						})
					})
				})
			},
			get_supplier(e) {
				this.submit_info.company_id = e.value;
				this.submit_info.company_id_name = e.label;

				this.get_linkman_list();
			},
			// 根据企业id 获取联系人
			get_linkman_list() {
				getAllLinkman({
					company_id: this.submit_info.company_id
				}).then(res => {
					let list_ = res.data;
					this.lxr_list = [];

					list_.forEach((n, k) => {
						this.lxr_list.push({
							label: n.username,
							value: n.id
						})
					})
				})
			},
			get_kehu_serch(e) {
				if (e.index === 1) {
					// 联系人
					this.lxr_id = e.value;
					this.lxr_name = e.label;
				}
			},
			// 获取起运港列表
			getStartHarborList() {
				startHarbor({
					page: 1,
					limit: 20000,
					service_type: 51
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.start_harbor_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_harbor(e) {
				this.submit_info.shipment_id = e.value;
				this.submit_info.shipment_id_name = e.label;
			},
			// 获取目的港列表
			getEndHarborList() {
				endHarbor({
					page: 1,
					limit: 20000,
					service_type: 51
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.end_harbor_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_end_harbor(e) {
				this.submit_info.destination_id = e.value;
				this.submit_info.destination_id_name = e.label;
			},
			// 获取hs编码列表
			getHsCodeList() {
				hsCode({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.hs_list.push({
							label: n.code,
							value: n.id
						})
					})
				})
			},
			get_hs(e) {
				this.huowu_list[e.index].hs_code = e.value;
				this.huowu_list[e.index].hs_code_name = e.label;
			},
			// 获取币制列表
			getCurrencyList() {
				currency({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.currency_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			get_currency(e) {
				this.huowu_list[e.index].currency_system = e.value;
				this.huowu_list[e.index].currency_system_name = e.label;
			},
			// 新增货物信息
			get_add_huowu() {
				let check_info = true;

				// this.huowu_list.forEach((n, k) => {
				// 	for (let i in n) {
				// 		if (!n[i] && i !== 'is_delete' && i !== 'flight_name' && i !== 'english_name' && i !== 'hs_code_name' && i !==
				// 			'hs_code') {
				// 			check_info = false;
				// 		}
				// 	}
				// })

				if (!check_info) return this.$message('请完善现有货物信息再添加下一条吧！')

				this.huowu_list.push({
					cases: '', // 件数
					pack: '', // 包装
					rough_weight: '', // 毛重
					weight: '', // 计重
					long: '', // 长
					tall: '', // 高
					broad: '', // 宽
					bulk: '', // 体积
					chinese_name: '', // 中文品名
					english_name: '', // 英文品名
					hs_code: '', // HS编码
					hs_code_name: '',
					low_value: '', // 货值
					currency_system: '', // 币制
					currency_system_name: '',
					flight_name: '', // 船名/航次
					shipment_date: '' // 起运日期
				})
			},
			get_del_huowu(index, item) {
				if (this.huowu_list.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						dcDelCagro({
							id: item.id
						}).then(res => {
							this.huowu_list.splice(index, 1);
						})
					} else {
						this.huowu_list.splice(index, 1);
					}
				})
			},
			// 提交
			addSubmit() {
				console.log(this.submit_info)

				// 验证
				for (let i in this.submit_info) {
					if (i !== 'receipt_order_no' && i !== 'order_no' && i !== 'createtime_text' && i !== 'price' && i !==
						'flight_name' && !this.submit_info[i] && i !== 'cargo' && i !== 'is_delete' && i !== 'linkman_id' && i !==
						'linkman_id_name' && i !== 'email' && i !== 'remark' && i !== 'waybill_no' && i !== 'company_id_name' && i !==
						'company_id' && i !== 'shipping_company' && i !== 'shipping_company_name' && i !== 'vessel_voyage')
						return this.$message(
							'请将内容填写完整');
				}

				if (this.huowu_list.length === 0) return this.$message('请填写货物信息');

				let check_info = true;

				// this.huowu_list.forEach((n, k) => {
				// 	for (let i in n) {
				// 		if (!n[i] && i !== 'is_delete' && i !== 'flight_name' && i !== 'english_name' && i !== 'hs_code_name' && i !==
				// 			'hs_code') {
				// 			check_info = false;
				// 		}
				// 	}
				// })

				if (!check_info) return this.$message('请完善货物信息');

				this.submit_info.cargo = this.huowu_list;
				// this.submit_info.linkman_id = this.lxr_id;
				this.submit_info.linkman_id = '';
				// this.submit_info.linkman_id_name = this.lxr_name;

				// 添加
				if (!this.id) {
					dcAdd(this.submit_info).then(res => {
						this.$message.success('添加成功！');
						this.$publicFun.get_link('/order/module/list', {});
					})
				}

				// 编辑
				if (this.id && this.bj) {
					dcEdit(this.submit_info).then(res => {
						this.$message.success('编辑成功！');
						this.$publicFun.get_link('/order/module/list', {});
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	.box01 {
		/deep/ .el-dialog__wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		/deep/ .el-dialog {
			margin: 0;
			margin-top: 0 !important;
			width: 515px !important;
			border-radius: 8px;
		}

		/deep/ .el-dialog__header {
			padding: 0;
		}

		/deep/ .el-dialog__body {
			padding: 0;
		}
	}
</style>
