<template>
	<div class="box01">
		<v-crumbs :crumbsType="7"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="$publicFun.get_link('/order/module/add', {})">新增</div>
				<div class="m_r10">
					<v-select02 :value_="formInfo.keyword" @serch="get_search" @empty="get_empty"></v-select02>
				</div>
				<v-select01 :width="157" :p_text="'订单编号'" :v_text="selectOption[0].label" :option="selectOption"
					@serch="get_option"></v-select01>
			</div>
			<div class="flex03">
				<div class="btn01" @click="get_derive">导出订舱单</div>
			</div>
		</div>
		<div class="form_box form_wrapper">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="order_no" label="订单编号" width="320"></el-table-column>
					<el-table-column prop="receipt_order_no" label="订舱单编号" width="320"></el-table-column>
					<el-table-column label="供应商名称" width="300">
						<template slot-scope="scope">
							<p>{{scope.row.company_id_name?scope.row.company_id_name: '-'}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="vessel_voyage" label="船名/航次" width="100"></el-table-column>
					<el-table-column prop="shipment_id_name" label="起运地/起运港" width="120"></el-table-column>
					<el-table-column prop="destination_id_name" label="目的地/目的港" width="120"></el-table-column>
					<el-table-column prop="info[0].cases" label="件数" width="100"></el-table-column>
					<el-table-column prop="info[0].rough_weight" label="毛重(KG）" width="100"></el-table-column>
					<el-table-column prop="info[0].chinese_name" label="中文品名" width="100"></el-table-column>
					<el-table-column prop="price" label="价格（RMB）" width="120">
						<template slot-scope="scope">
							<div style="height: 20px;">
								<div
									style="position: absolute;top: 50%;left: 21px;right: 21px;-webkit-transform: translateY(-50%);">
									<el-input v-model="scope.row.price" placeholder="" @blur="get_blur(scope.row)">
									</el-input>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="createtime_text" label="添加时间" width="160"></el-table-column>
					<el-table-column fixed="right" label="操作" width="220">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_"
									@click="$publicFun.get_link('/order/module/add?id=' + scope.row.id, {})">查看</p>
								<p class="c_6d9fff m_r10 pointer_"
									@click="$publicFun.get_link('/order/module/add?bj=true&id=' + scope.row.id, {})">编辑
								</p>
								<p class="c_ff6f6f pointer_" @click="clickDel(scope.row.id)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<!-- <div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div> -->
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page"
				layout="prev, pager, next, jumper, sizes" :total="total"
				:page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange"
				@size-change="handleSizeChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select01 from '../../components/select/index.vue'; // 选择器
	import select02 from '../../components/select/index01.vue'; // 搜索框

	import {
		dcList,
		editPrice,
		dcDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select01': select01,
			'v-select02': select02
		},
		data() {
			return {
				list: [],
				total: null,
				choose_id: [],
				selectOption: [{
					value: 1,
					label: '订单编号'
				}, {
					value: 2,
					label: '订舱单编号'
				}, {
					value: 3,
					label: '供应商名称'
				}], // 下拉选择框
				formInfo: {
					type_id: 1,
					keyword: '',
					page: 1,
					limit: 10
				},
			}
		},
		created() {
			this.getDcList()
		},
		methods: {
			// 订单列表
			getDcList() {
				dcList(this.formInfo).then(res => {
					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 搜索
			get_search(e) {
				if (!e) {
					this.$message('请输入搜索内容!');
					return
				}

				this.formInfo.page = 1;
				this.formInfo.keyword = e;
				this.getDcList();
				window.scroll(0, 0);
			},
			// 清空搜索
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.keyword = '';

				this.getDcList();
			},
			// 下拉框选择
			get_option(e) {
				this.formInfo.page = 1;
				this.formInfo.type_id = e;
				this.formInfo.keyword = '';
				this.getDcList();
				window.scroll(0, 0);
			},
			// 价格失去焦点
			get_blur(e) {
				editPrice({
					id: e.id,
					price: e.price
				}).then(res => {
					this.$message.success('价格修改成功！');
					this.getDcList();
				})
			},
			// 删除
			clickDel(id) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					dcDel({
						id: id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.getDcList()
						this.$message.success('删除成功')
					})
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.getDcList();
				window.scroll(0, 0);
			},
			// 点击一页请求数据
			handleSizeChange(num) {
				this.formInfo.page = 1;
				this.formInfo.limit = num;
				this.getDcList();
				window.scroll(0, 0);
			},
			handleSelectionChange(e) {
				this.choose_id = [];

				if (e.length !== 0) {
					e.forEach((n, k) => {
						this.choose_id.push(n.id)
					})
				}
			},
			// 导出
			get_derive() {
				if (this.choose_id.length === 0) return this.$message('请至少选中一个订舱单');

				let c_id = this.choose_id.join(',');

				this.$publicFun.get_link('/order/pdf?id=' + c_id);
			}
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
