//新增报价
const offerAdd = {
    namespaced:true,
    state:{
        formInfo:{}
    },
    mutations:{
        getFormInfo(state,val){
            state.formInfo = val
        }
    },
    actions:{}
}

export default offerAdd