<template>
	<div class="box01">
		<v-crumbs :crumbsType="28"></v-crumbs>
		<div class="form_box">
			<div style="flex: 1;overflow: hidden;">
				<template>
					<el-table ref="multipleTable" height="600" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" fixed width="46"></el-table-column>
						<el-table-column prop="id" label="ID" width="70"></el-table-column>
						<el-table-column prop="nickname" label="微信昵称" width="180"></el-table-column>
						<el-table-column label="头像" width="180">
							<template slot-scope="scope">
								<div style="height: 20px;"></div>
								<div style="position: absolute;top: 0;left: 0;right: 0;">
									<el-image style="width: 45px; height: 45px" :src="scope.row.avatar_text" fit="cover"></el-image>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sex_text" label="性别" width="180"></el-table-column>
						<el-table-column prop="birthday" label="生日" width="180"></el-table-column>
						<el-table-column prop="mobile" label="手机号" width="180"></el-table-column>
						<el-table-column prop="createtime_text" label="添加时间"></el-table-column>
						<el-table-column fixed="right" label="操作">
							<template slot-scope="scope">
								<div class="flex04">
									<p class="c_6d9fff m_r10 pointer_" @click="get_detail(scope.row)">查看</p>
									<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div>
		<!-- 模态 -->
		<v-modal :type="5" :detail="info" :dialogVisible="dialogVisible" @getModalClose="getModalClose"></v-modal>
	</div>
</template>

<script>
	import modal from '../../components/wx-modal/index.vue'; // 模态
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import rightNav from '../../components/wx-nav/index.vue'; // 右侧导航

	import {
		memberList,
		memberShow,
		memberDel
	} from '@/api/index';

	export default {
		components: {
			'v-modal': modal,
			'v-crumbs': crumbs,
			'v-right-nav': rightNav
		},
		data() {
			return {
				list: [],
				info: {},
				total: null,
				detail_info: {},
				dialogVisible: false,
				formInfo: {
					page: 1,
					limit: 50
				},
			}
		},
		created() {
			this.get_user_list();
		},
		methods: {
			// 获取用户列表
			get_user_list() {
				memberList(this.formInfo).then(res => {
					res.data.data.forEach((n, k) => {
						if (!n.birthday) {
							n.birthday = '-'
						}

						if (!n.mobile) {
							n.mobile = '-'
						}
					})

					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_user_list();
				window.scroll(0, 0);
			},
			// 查看详情
			get_detail(item) {
				memberShow({
					id: item.id
				}).then(res => {
					this.dialogVisible = true;
					this.info = res.data[0];
				})
			},
			getModalClose(e) {
				this.dialogVisible = e
			},
			// 删除
			get_del(item) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					memberDel({
						id: item.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_user_list();
						this.$message.success('删除成功');
					})
				})
			},
			handleSelectionChange(e) {

			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}

	/deep/ .form_box {
		display: flex;

		tr {
			td {
				padding: 20px 0;
			}
		}
	}

	/deep/ .el-image {
		margin: 6px auto;
	}
</style>
