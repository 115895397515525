<template>
	<div class="box01">
		<v-crumbs :crumbsType="3"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<v-select02 :p_text="'请输入关键字搜索'" :value_="formInfo.keyword" @serch="get_serch" @empty="get_empty"></v-select02>
				<div class="m_r10"></div>
				<v-select01 :width="157" :p_text="''" :v_text="selectOption[0].label" :option="selectOption" @serch="get_option"></v-select01>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="name" label="报价模板名称"></el-table-column>
					<el-table-column prop="service_type.name" label="服务类型"></el-table-column>
					<el-table-column prop="services_scope.name" label="服务范围"></el-table-column>
					<el-table-column prop="admin_user.nickname" label="添加人员"></el-table-column>
					<el-table-column prop="createtime_text" label="添加时间"></el-table-column>
					<el-table-column fixed="right" label="操作" width="220">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/offer/form/edit?id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<!-- <div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div> -->
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page"
				layout="prev, pager, next, jumper, sizes" :total="total"
				:page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange"
				@size-change="handleSizeChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select01 from '../../components/select/index.vue'; // 选择器
	import select02 from '../../components/select/index01.vue'; // 搜索框

	import {
		templateOfferList,
		templateOfferEdit,
		templateOfferShow,
		templateOfferDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select01': select01,
			'v-select02': select02,
		},
		data() {
			return {
				list: [],
				total: null,
				detail_info: {},
				formInfo: {
					page: 1,
					limit: 10,
					type_id: 1, // 类型 1:模板名称2服务类型3:服务范围
					keyword: '' // 关键字
				},
				selectOption: [{
					value: 1,
					label: '模板名称'
				}, {
					value: 2,
					label: '服务类型'
				}, {
					value: 3,
					label: '服务范围'
				}], // 下拉选择框
			}
		},
		created() {
			this.get_list();
		},
		methods: {
			// 列表
			get_list() {
				templateOfferList(this.formInfo).then(res => {
					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_list();
				window.scroll(0, 0);
			},
			// 点击一页请求数据
			handleSizeChange(num) {
				this.formInfo.page = 1;
				this.formInfo.limit = num;
				this.get_list();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入关键字');

				this.formInfo.page = 1;
				this.formInfo.keyword = e;

				this.get_list();
			},
			// 清空搜索
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.keyword = '';

				this.get_list();
			},
			// 下拉选择框
			get_option(e) {
				this.formInfo.page = 1;
				this.formInfo.keyword = '';
				this.formInfo.type_id = e;

				this.get_list();
			},
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					templateOfferDel({
						id: e.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_list();
						this.$message.success('删除成功');
					})
				})
			},
			handleSelectionChange(e) {

			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
