<template>
	<div class="box01">
		<v-crumbs :crumbsType="19"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="get_detail(1)">新增</div>
			</div>
		</div>
		<div class="form_box">
			<div style="flex: 1;overflow: hidden;">
				<template>
					<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" fixed width="46"></el-table-column>
						<el-table-column prop="id" label="ID" width="70"></el-table-column>
						<el-table-column label="主图">
							<template slot-scope="scope">
								<div style="height: 20px;"></div>
								<div style="position: absolute;top: 0;left: 0;right: 0;">
									<el-image style="width: 86px; height: 48px" :src="scope.row.url_text" fit="cover"></el-image>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="title" label="标题"></el-table-column>
						<el-table-column prop="createtime_text" label="添加时间"></el-table-column>
						<el-table-column fixed="right" label="操作">
							<template slot-scope="scope">
								<div class="flex04">
									<p class="c_6d9fff m_r10 pointer_" @click="get_detail(2, scope.row)">编辑</p>
									<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
			<div class="form_right_nav_wrapper">
				<v-right-nav :index="0"></v-right-nav>
			</div>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div>
		<!-- 模态 -->
		<v-modal :type="1" :detail="detail_info" :dialogVisible="dialogVisible" @getModalClose="getModalClose"
		 @get_data_amend="get_data_amend"></v-modal>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import modal from '../../components/wx-modal/index.vue'; // 弹窗
	import rightNav from '../../components/wx-nav/index.vue'; // 右侧

	import {
		bannerList,
		bannerShow,
		bannerEdit,
		bannerAdd,
		bannerDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-modal': modal,
			'v-right-nav': rightNav
		},
		data() {
			return {
				list: [],
				total: null,
				detail_info: {},
				dialogVisible: false,
				formInfo: {
					page: 1,
					limit: 10
				},
			}
		},
		created() {
			this.get_banner_list();
		},
		methods: {
			get_banner_list() {
				bannerList(this.formInfo).then(res => {
					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_banner_list();
				window.scroll(0, 0);
			},
			// 添加/编辑
			get_detail(index, item) {
				if (index == 1) {
					// 添加
					this.detail_info = {};
				}

				if (index == 2) {
					// 编辑
					bannerShow({
						id: item.id
					}).then(res => {
						this.detail_info = res.data;
					})
				}

				this.dialogVisible = true;
			},
			get_data_amend(e) {
				this.dialogVisible = false;

				if (e.id) { // 编辑
					bannerEdit({
						id: e.id,
						title: e.title,
						url: e.url
					}).then(res => {
						this.$message.success('修改成功');
						this.get_banner_list();
					})
				}

				if (!e.id) { // 添加
					bannerAdd({
						title: e.title,
						url: e.url
					}).then(res => {
						this.$message.success('添加成功');
						this.get_banner_list();
					})
				}
			},
			// 关闭模态
			getModalClose(e) {
				this.dialogVisible = e
			},
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					bannerDel({
						id: e.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_banner_list();
						this.$message.success('删除成功');
					})
				})
			},
			handleSelectionChange(e) {

			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}

	/deep/ .form_box {
		display: flex;

		tr {
			td {
				padding: 20px 0;
			}
		}
	}

	/deep/ .el-image {
		margin: 6px auto;
	}
</style>
