<template>
	<div class="box01">
		<v-crumbs :crumbsType="9" @get_mxy="get_mxy"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<v-select02 :p_text="'应收结算单号/运单编号'" :value_="formInfo.receivable_no" @serch="get_serch"
					@empty="get_empty"></v-select02>
				<div class="btn01 m_l10" @click="modal_show = true">高级搜索</div>
			</div>
			<div class="flex03">
				<div class="btn01" @click="get_collect">预览汇总账单</div>
				<div class="btn01 m_l10" @click="get_derive">预览</div>
				<div class="btn01 m_l10" @click="get_derive">导出</div>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="receivable_no" label="应收结算单号" width="180"></el-table-column>
					<el-table-column prop="order_no" label="订单编号" width="180"></el-table-column>
					<el-table-column label="运单编号" width="220">
						<template slot-scope="scope">
							<div>{{scope.row.orders?scope.row.orders.waybill_no: '-'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="company_id_name" label="客户名称" width="380"></el-table-column>
					<el-table-column prop="payment_days_name" label="结算账期" width="130"></el-table-column>
					<el-table-column prop="reconciliation_id_text" label="对账情况" width="130"></el-table-column>
					<el-table-column prop="make_invoice_text" label="开票情况" width="130"></el-table-column>
					<el-table-column prop="collected" label="待收金额(RMB)" width="130"></el-table-column>
					<el-table-column prop="receivable" label="应收金额(RMB)" width="130"></el-table-column>
					<el-table-column prop="received" label="已收金额(RMB)" width="130"></el-table-column>
					<el-table-column prop="bidder" label="负责人" width="130"></el-table-column>
					<el-table-column prop="remark" label="备注" width="130"></el-table-column>
					<!-- <el-table-column label="财务批注" width="200">
						<template slot-scope="scope">
							<span :class="{del:scope.row.approval_list.apply_status == 1}" class="text-del"
								v-if="scope.row.approval_list">{{scope.row.approval_list.remark}}</span>
						</template>
					</el-table-column> -->
					<el-table-column label="操作" width="220" fixed="right">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_"
									@click="$publicFun.get_link('/settle/edit?id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f m_r10 pointer_" @click="get_del(scope.row)">删除</p>
								<!-- <span class="cwBtn"
									v-if="!scope.row.approval_list || (scope.row.approval_list && scope.row.approval_list.apply_status === 1)"
									@click="getFinanceData(scope.row)">财务批注</span>
								<span class="cwBtn"
									v-if="scope.row.approval_list && scope.row.approval_list.apply_status === 0"
									@click="financeAgain(scope.row)">已处理</span> -->
							</div>
						</template>
					</el-table-column>
					<el-table-column label="凭证" width="100" fixed="right">
						<template slot-scope="scope">
							<img src="../../assets/04@2x.png" style="height: 20px;margin: 0 auto;cursor: pointer;"
								@click="get_voucher(scope.row)" />
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page"
				layout="prev, pager, next, jumper, sizes" :total="total"
				:page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange"
				@size-change="handleSizeChange"></el-pagination>
		</div>
		<!-- 高级搜索 -->
		<v-screen :modal_show="modal_show" @getExit="getExit" @getInquire="get_inquire"></v-screen>
		<!-- 凭证 -->
		<v-voucher :dialogVisible="dialogVisible" :detail="detail_info" @getModalClose="getModalClose"
			@get_data_amend="get_data_amend"></v-voucher>
		<!-- 财务批注弹窗 -->
		<!-- <v-finance :dialogVisible="financeShow" :financeData="financeData" @getModalClose="financeClose"
			@getSubmit="getSubmit"></v-finance> -->
	</div>
</template>

<script>
	import voucher from '../../components/voucher/index.vue'; // 凭证
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select02 from '../../components/select/index01.vue'; // 搜索框
	import screen from '../../components/screen/index01.vue'; // 高级搜索
	import finance from '@/components/finance/index.vue'; // 高级搜索

	import {
		costInfoList,
		costInfoShow,
		costInfoEdit,
		costImageList,
		costImageAdd,
		costInfoDel,
		getItemsData,
		financeAgainFun,
		getModalCloseFun,
		getSubmitFun,
		getFinanceDataFun
	} from '@/api/index';

	export default {
		components: {
			'v-screen': screen,
			'v-crumbs': crumbs,
			'v-voucher': voucher,
			'v-finance': finance,
			'v-select02': select02
		},
		data() {
			return {
				financeShow: false,
				financeData: {
					type_id: 2, //1-订单 2-应收 3-应付
					source_id: "",
					remark: "",
					op_type: 0, //0-输入,1-勾选
				},
				financeIdx: null,

				list: [],
				total: null,
				choose_id: [],
				detail_info: {},
				dialogVisible: false,
				modal_show: false, // 高级搜索
				formInfo: {
					page: 1,
					limit: 10,
					order_no: '', // 订单编号
					receivable_no: '', // 应收编号
					company_name: '', // 客户名称
					linkman: '', // 负责人
					reconciliation_id: '', // 对账情况
					make_invoice: '', // 开票情况
				},
			}
		},
		created() {
			this.get_list();
		},
		methods: {
			// 已处理
			financeAgain(e) {
				financeAgainFun(this, e)
			},
			//关闭
			financeClose(e) {
				getModalCloseFun(this, e)
			},
			//提交
			getSubmit(e) {
				getSubmitFun(this, e)
			},
			// 财务批注
			getFinanceData(e) {
				console.log('getFinanceData')
				getFinanceDataFun(this, e)
			},
			get_mxy() {
				this.formInfo = {
					page: 1,
					limit: 10,
					order_no: '', // 订单编号
					receivable_no: '', // 应收编号
					company_name: '', // 客户名称
					linkman: '', // 负责人
					reconciliation_id: '', // 对账情况
					make_invoice: '', // 开票情况
				}

				this.get_list();
			},
			// 列表
			get_list() {
				costInfoList(this.formInfo).then(res => {
					res.data.data.forEach((n, k) => {
						if (!n.remark) {
							n.remark = '-';
						}
					})

					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_list();
				window.scroll(0, 0);
			},
			// 点击一页请求数据
			handleSizeChange(num) {
				this.formInfo.page = 1;
				this.formInfo.limit = num;
				this.get_list();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入应收结算单号');

				this.formInfo.page = 1;
				this.formInfo.receivable_no = e;
				this.formInfo.order_no = '';
				this.formInfo.company_name = '';
				this.formInfo.linkman = ''; // 负责人
				this.formInfo.reconciliation_id = ''; // 对账情况
				this.formInfo.make_invoice = ''; // 开票情况

				this.get_list();
			},
			// 清空搜索
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.receivable_no = '';
				this.formInfo.order_no = '';
				this.formInfo.company_name = '';
				this.formInfo.linkman = ''; // 负责人
				this.formInfo.reconciliation_id = ''; // 对账情况
				this.formInfo.make_invoice = ''; // 开票情况

				this.get_list();
			},
			// 查询
			get_inquire(e) {
				this.formInfo.page = 1;
				this.formInfo.receivable_no = '';
				this.formInfo.order_no = e.order_no;
				this.formInfo.company_name = e.company_name;
				this.formInfo.linkman = e.linkman; // 负责人
				this.formInfo.reconciliation_id = e.reconciliation_id; // 对账情况
				this.formInfo.make_invoice = e.make_invoice; // 开票情况

				this.get_list();
			},
			// 高级搜索退出
			getExit() {
				this.modal_show = false;
			},
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					costInfoDel({
						id: e.id,
						order_id: e.order_id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_list();
						this.$message.success('删除成功');
					})
				})
			},
			// 凭证
			get_voucher(e) {
				console.log('wwww', e)
				costImageList({
					order_id: e.order_id
				}).then(res => {
					console.log('res.data.,', res.data)
					this.dialogVisible = true;
					this.detail_info = {
						order_id: e.order_id,
						order_no: e.order_no,
						imgs: res.data,
						type_id: '',
						type_name: ''
					}
					console.log('this.detail_info', this.detail_info)
				})
			},
			getModalClose(e) {
				this.dialogVisible = e;
			},
			get_data_amend(e) {
				console.log(e)
				let img_array = [];
				this.dialogVisible = false;

				e.imgs.forEach((n, k) => {
					if (!n.id) {
						img_array.push(n.images)
					}
				})

				if (img_array.length === 0) return

				costImageAdd({
					order_id: e.order_id,
					image: img_array.join(','),
					type_id: e.type_id,
				}).then(res => {
					this.$message.success('上传凭证成功');
					this.get_list();
				})
			},
			//
			handleSelectionChange(e) {
				this.choose_id = [];

				if (e.length !== 0) {
					e.forEach((n, k) => {
						this.choose_id.push(n.id)
					})
				}
			},
			get_derive() {
				if (this.choose_id.length === 0) return this.$message('请至少选中一个应收结算单');

				let c_id = this.choose_id.join(',');

				this.$publicFun.get_link('/settle/pdf?id=' + c_id);
			},
			get_collect() {
				if (this.choose_id.length === 0) return this.$message('请至少选中一个应收结算单');

				let c_id = this.choose_id.join(',');

				getItemsData({
					sid: c_id
				}).then(res => {
					this.$publicFun.get_link('/settle/collect/pdf?type=1&id=' + c_id);
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
