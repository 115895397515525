<template>
	<div class="">
		<div class="right_nav_content hid_">
			<div class="right_nav_list" @click="$publicFun.get_link(item.url, {})" :class="{'active': index == index_}" v-for="(item, index_) in list" :key="index_">
				<img :src="item.icon" />
				<p>{{item.name}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			index: {
				type: Number
			}
		},
		data() {
			return {
				list: [{
					name: '服务类型',
					icon: require('../../assets/set/01@2x.png'),
					url: '/info/parameter/set01'
				}, {
					name: '运输流向',
					icon: require('../../assets/set/02@2x.png'),
					url: '/info/parameter/set02'
				}, {
					name: '运输方式',
					icon: require('../../assets/set/03@2x.png'),
					url: '/info/parameter/set03'
				}, {
					name: '服务范围',
					icon: require('../../assets/set/04@2x.png'),
					url: '/info/parameter/set04'
				}, {
					name: '起运地/起运港',
					icon: require('../../assets/set/05@2x.png'),
					url: '/info/parameter/set05'
				}, {
					name: '目的地/目的港',
					icon: require('../../assets/set/06@2x.png'),
					url: '/info/parameter/set06'
				}, {
					name: '费用名称',
					icon: require('../../assets/set/07@2x.png'),
					url: '/info/parameter/set07'
				}, {
					name: '结算账期',
					icon: require('../../assets/set/08@2x.png'),
					url: '/info/parameter/set08'
				}, {
					name: '结算方式',
					icon: require('../../assets/set/09@2x.png'),
					url: '/info/parameter/set09'
				}, {
					name: '线路',
					icon: require('../../assets/set/10@2x.png'),
					url: '/info/parameter/set10'
				}, {
					name: '包装',
					icon: require('../../assets/set/11@2x.png'),
					url: '/info/parameter/set11'
				}, {
					name: '币制',
					icon: require('../../assets/set/12@2x.png'),
					url: '/info/parameter/set12'
				}, {
					name: '国际城市',
					icon: require('../../assets/set/13@2x.png'),
					url: '/info/parameter/set13'
				}, {
					name: '航空公司',
					icon: require('../../assets/set/14@2x.png'),
					url: '/info/parameter/set14'
				}, {
					name: '船公司',
					icon: require('../../assets/set/15@2x.png'),
					url: '/info/parameter/set15'
				}, {
					name: 'HS编码',
					icon: require('../../assets/set/16@2x.png'),
					url: '/info/parameter/set16'
				}]
			}
		}
	}
</script>

<style lang="less">
	.right_nav_content {
		height: 100%;
		background-color: #35465A;

		.right_nav_list {
			color: #fff;
			height: 35px;
			cursor: pointer;
			margin: 13px 10px;
			display: flex;
			font-size: 12px;
			line-height: 17px;
			align-items: center;
			border-radius: 7px;
			background-color: #3C4D5F;

			img {
				width: 14px;
				margin: 0 9px 0 15px;
			}

			&.active {
				background-color: #5B8EF0;
			}
		}
	}
</style>
