<template>
	<div class="box01">
		<v-crumbs :crumbsType="25"></v-crumbs>
		<div class="form_box">
			<div style="flex: 1;overflow: hidden;">
				<template>
					<el-table ref="multipleTable" height="600" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
						<el-table-column type="selection" fixed width="46"></el-table-column>
						<el-table-column prop="id" label="ID" width="70"></el-table-column>
						<el-table-column prop="member.nickname" label="微信昵称" width="145"></el-table-column>
						<el-table-column prop="member.mobile" label="联系方式" width="145"></el-table-column>
						<el-table-column prop="shipping_type" label="运输方式" width="210"></el-table-column>
						<el-table-column prop="remark" label="备注"></el-table-column>
						<el-table-column prop="handler" label="处理人" width="165"></el-table-column>
						<el-table-column prop="createtime_text" label="添加时间" width="230"></el-table-column>
						<el-table-column fixed="right" label="操作" width="220">
							<template slot-scope="scope">
								<div class="flex04">
									<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/weixin/parament/goods/detail?id=' + scope.row.id, {})">查看</p>
									<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑

	import {
		cargoEnquiryList,
		cargoEnquiryShow,
		cargoEnquiryEdit,
		cargoEnquiryDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs
		},
		data() {
			return {
				list: [],
				total: null,
				detail_info: {},
				dialogVisible: false,
				formInfo: {
					page: 1,
					limit: 50
				},
			}
		},
		created() {
			this.get_cargo_enquiry_list();
		},
		methods: {
			// 询价列表
			get_cargo_enquiry_list() {
				cargoEnquiryList(this.formInfo).then(res => {
					res.data.data.forEach((n, k) => {
						if (!n.remark) {
							n.remark = '-';
						}

						if (!n.handler) {
							n.handler = '-';
						}
					})

					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_cargo_enquiry_list();
				window.scroll(0, 0);
			},
			// 删除
			get_del(item) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					cargoEnquiryDel({
						id: item.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_cargo_enquiry_list();
						this.$message.success('删除成功');
					})
				})
			},
			handleSelectionChange(e) {

			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}

	/deep/ .form_box {
		display: flex;

		tr {
			td {
				padding: 20px 0;
			}
		}
	}

	/deep/ .el-image {
		margin: 6px auto;
	}
</style>
