<template>
	<div class="box01">
		<v-crumbs :crumbsType="29"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="$publicFun.get_link('/authority/administrator/add', {})">新增</div>
				<v-select02 :p_text="'用户名称'" :value_="formInfo.keyword" @serch="get_serch" @empty="get_empty"></v-select02>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="username" label="用户名" width="180"></el-table-column>
					<el-table-column prop="nickname" label="昵称" width="320"></el-table-column>
					<el-table-column prop="groups.group.name" label="所属组别" width="180"></el-table-column>
					<el-table-column label="状态" width="180">
						<template slot-scope="scope">
							<p>{{scope.row.status === 'normal'? '开启': '关闭'}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="logintime_text" label="最后登录时间"></el-table-column>
					<el-table-column fixed="right" label="操作">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/authority/administrator/add?id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
			 :total="total" @current-change="currentChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select02 from '../../components/select/index01.vue'; // 搜索框

	import {
		managerList,
		managerDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select02': select02
		},
		data() {
			return {
				list: [],
				total: null,
				detail_info: {},
				formInfo: {
					page: 1,
					limit: 10,
					keyword: '' // 关键字
				},
			}
		},
		created() {
			this.get_list();
		},
		methods: {
			// 列表
			get_list() {
				managerList(this.formInfo).then(res => {
					res.data.data.forEach((n, k) => {
						// n.logintime_text = '-'

						if (!n.groups.group) {
							n.groups.group = {
								name: '-'
							}
						}
					})

					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_list();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入关键字');

				this.formInfo.page = 1;
				this.formInfo.keyword = e;
				this.get_list();
			},
			// 清空
			get_empty() {
				this.formInfo.page = 1;
				this.formInfo.keyword = '';
				this.get_list();
			},
			// 删除
			get_del(e) {
				if (e.id == 1) return this.$message.error('不可删除')

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					managerDel({
						id: e.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_list();
						this.$message.success('删除成功');
					})
				})
			},
			handleSelectionChange(e) {

			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
