<template>
	<div>
		<div style="color: #fff;" v-for="(item,index) in list" @click="get_list(index)">{{item}}</div>
		<div>{{li}}</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				li: '99',
				list: [{
					a: '5'
				}, {
					a: '6'
				}]
			}
		},
		created() {
			console.log(5)
		},
		mounted() {
			console.log(8)
		},
		methods: {
			get_list(index) {
				
			}
		}
	}
</script>

<style>
</style>
