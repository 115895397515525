<template>
	<div class="box01">
		<v-crumbs :crumbsType="0" :back="true" @get_mxy="get_mxy"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="$publicFun.get_link('/offer/add', {})">新增</div>
				<v-select02 :p_text="'报价编号'" :value_="formInfo.order_no" @serch="get_serch" @empty="get_empty"></v-select02>
				<div class="btn01 m_l10" @click="modal_show = true">高级搜索</div>
			</div>
			<div class="flex03">
				<div class="btn01" @click="get_derive">报价预览</div>
				<div class="btn01 m_l10" @click="get_shift_order">转入订单</div>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="order_no" label="报价编号" width="160"></el-table-column>
					<el-table-column prop="service_type.name" label="服务类型" width="110"></el-table-column>
					<el-table-column prop="services_scope.name" label="服务范围" width="110"></el-table-column>
					<el-table-column prop="company.company_name" label="客户名称" width="260"></el-table-column>
					<el-table-column label="起运地/起运港" width="260">
						<template slot-scope="scope">
							{{scope.row.shipment.name}}/{{scope.row.shipment.code}}
						</template>
					</el-table-column>
					<el-table-column label="目的地/目的港" width="260">
						<template slot-scope="scope">
							{{scope.row.destination.name}}/{{scope.row.destination.code}}
						</template>
					</el-table-column>
					<el-table-column label="报价确认" width="120">
						<template slot-scope="scope">
							<p>{{scope.row.offer_confirm == 1?'是': '否'}}</p>
						</template>
					</el-table-column>
					<el-table-column label="转入订单" width="120">
						<template slot-scope="scope">
							<p>{{scope.row.turn_order == 1?'是': '否'}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="bidder" label="报价人员" width="120"></el-table-column>
					<el-table-column prop="createtime_text" label="添加时间" width="200"></el-table-column>
					<el-table-column fixed="right" label="操作" width="220">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="m_r10 pointer_" @click="$publicFun.get_link('/offer/edit?id=' + scope.row.id, {})">成本编辑</p>
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/offer/edit?type=1&id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
			<!-- <div class="pagination">
				<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper"
				 :total="total" @current-change="currentChange"></el-pagination>
			</div> -->
			<div class="pagination">
				<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page"
					layout="prev, pager, next, jumper, sizes" :total="total"
					:page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange"
					@size-change="handleSizeChange"></el-pagination>
			</div>
		</div>
		<!-- 高级搜索 -->
		<v-screen :modal_show="modal_show" @getExit="getExit" @getInquire="getInquire"></v-screen>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select01 from '../../components/select/index.vue'; // 选择器
	import select02 from '../../components/select/index01.vue'; // 搜索框
	import screen from '../../components/screen/index.vue'; // 高级搜索

	import {
		offerList,
		offerAdd,
		offerEdit,
		offerShow,
		offerDel,
		offerOrder
	} from '@/api/index';

	export default {
		components: {
			'v-screen': screen,
			'v-crumbs': crumbs,
			'v-select01': select01,
			'v-select02': select02
		},
		data() {
			return {
				list: [],
				total: null,
				choose_id: [],
				detail_info: {},
				modal_show: false, // 高级搜索
				formInfo: {
					page: 1,
					limit: 10,
					order_no: '', // 订单编号
					start_time: '', // 报价开始日期
					end_time: '', // 报价结束日期
					service_type_id: '', // 服务类型
					bidder: '', // 报价人员
					company_id: '', // 客户名称
					offer_confirm: '',
					shipment_id: '', // 起运地/起运港
					destination_id: '', // 目的地/目的港
				},
			}
		},
		created() {
			this.getOfferList()
		},
		methods: {
			get_mxy() {
				this.formInfo = {
					page: 1,
					limit: 10,
					order_no: '', // 订单编号
					start_time: '', // 报价开始日期
					end_time: '', // 报价结束日期
					service_type_id: '', // 服务类型
					bidder: '', // 报价人员
					company_id: '', // 客户名称
					offer_confirm: '',
					shipment_id: '', // 起运地/起运港
					destination_id: '', // 目的地/目的港
				}

				this.getOfferList();
			},
			// 获取列表
			getOfferList() {
				offerList(this.formInfo).then(res => {
					this.total = res.data.total;
					this.list = res.data.data;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.getOfferList();
				window.scroll(0, 0);
			},
			// 点击一页请求数据
			handleSizeChange(num) {
				this.formInfo.page = 1;
				this.formInfo.limit = num;
				this.getOfferList();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入报价编号');

				this.formInfo.page = 1;
				this.formInfo.order_no = e // 订单编号
				this.formInfo.start_time = '' // 报价开始日期
				this.formInfo.end_time = '' // 报价结束日期
				this.formInfo.service_type_id = '' // 服务类型
				this.formInfo.bidder = '' // 报价人员
				this.formInfo.company_id = '' // 客户名称
				this.formInfo.offer_confirm = ''
				this.formInfo.shipment_id = '' // 起运地/起运港
				this.formInfo.destination_id = '' // 目的地/目的港

				this.getOfferList();
			},
			// 清空搜索
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.order_no = '' // 订单编号
				this.formInfo.start_time = '' // 报价开始日期
				this.formInfo.end_time = '' // 报价结束日期
				this.formInfo.service_type_id = '' // 服务类型
				this.formInfo.bidder = '' // 报价人员
				this.formInfo.company_id = '' // 客户名称
				this.formInfo.offer_confirm = ''
				this.formInfo.shipment_id = '' // 起运地/起运港
				this.formInfo.destination_id = '' // 目的地/目的港

				this.getOfferList();
			},
			// 高级搜索
			getInquire(e) {
				this.formInfo.page = 1;
				this.formInfo.order_no = '' // 订单编号
				this.formInfo.start_time = e.start_time // 报价开始日期
				this.formInfo.end_time = e.end_time // 报价结束日期
				this.formInfo.service_type_id = e.service_type_id // 服务类型
				this.formInfo.bidder = e.bidder // 报价人员
				this.formInfo.company_id = e.company_id // 客户名称
				this.formInfo.offer_confirm = e.offer_confirm
				this.formInfo.shipment_id = e.shipment_id // 起运地/起运港
				this.formInfo.destination_id = e.destination_id // 目的地/目的港

				this.getOfferList();
			},
			getExit() {
				this.modal_show = false;
			},
			// 转入订单
			get_shift_order() {
				if (this.choose_id.length === 0) return this.$message('请至少选中一个报价单');

				offerOrder({
					id: this.choose_id.join(',')
				}).then(res => {
					this.$message.success('转入成功');
					this.formInfo.page = 1;
					this.getOfferList();
				})
			},
			// 选中id
			handleSelectionChange(e) {
				this.choose_id = [];

				if (e.length !== 0) {
					e.forEach((n, k) => {
						this.choose_id.push(n.id)
					})
				}
			},
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					offerDel({
						id: e.id
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.getOfferList();
						this.$message.success('删除成功');
					})
				})
			},
			// 导出
			get_derive() {
				if (this.choose_id.length === 0) return this.$message('请至少选中一个报价单');

				let c_id = this.choose_id.join(',');

				this.$publicFun.get_link('/offer/pdf?id=' + c_id);
			}
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
