<template>
	<div class="box01">
		<v-crumbs :crumbsType='crumbs_num'></v-crumbs>
		<div style="position: relative;">
			<div class="shade_wrapper" v-if="id && !bj"></div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>基本信息</p>
						</div>
						<div class="info_wrapper top">
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">合作性质:</p>
									<div class="form_choose_content w_377">
										<div class="form_choose_block" :class="{'active': submit_info.partner_type == 1}">
											<i @click="submit_info.partner_type = 1"></i>
											<p>供应商</p>
										</div>
										<div class="form_choose_block" :class="{'active': submit_info.partner_type == 2}">
											<i @click="submit_info.partner_type = 2"></i>
											<p>客户</p>
										</div>
										<div class="form_choose_block" :class="{'active': submit_info.partner_type == 3}">
											<i @click="submit_info.partner_type = 3"></i>
											<p>其他</p>
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_choose_wrapper">
									<p class="form_input_label">合作协议:</p>
									<div class="form_choose_content w_377">
										<div class="form_choose_block" :class="{'active': submit_info.is_partner == 1}">
											<i @click="submit_info.is_partner = 1"></i>
											<p>有</p>
										</div>
										<div class="form_choose_block" :class="{'active': submit_info.is_partner == 2}">
											<i @click="submit_info.is_partner = 2"></i>
											<p>无</p>
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">协议编号:</p>
									<div class="form_input w_377">
										<el-input v-model="submit_info.contract_number" placeholder="请填写协议编号"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">协议有效期:</p>
									<div class="flex01">
										<div class="form_input w_137">
											<el-date-picker v-model="submit_info.star_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
										</div>
										<div class="form_line_">
											<p></p>
										</div>
										<div class="form_input w_137">
											<el-date-picker v-model="submit_info.end_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
										</div>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">所在国家:</p>
									<div class="form_input w_194">
										<v-select :list="gj_list" :value_name="submit_info.country_name" @result="get_result"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="display: block;">
								<div class="form_input_wrapper" style="padding-right: 3%;">
									<p class="form_input_label">企业名称:</p>
									<div class="form_input form_all_input">
										<el-input v-model="submit_info.company_name" placeholder="请填写企业名称"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block" style="display: block;margin-top: 18px;">
								<div class="form_input_wrapper" style="padding-right: 3%;">
									<p class="form_input_label">英文名称:</p>
									<div class="form_input form_all_input">
										<el-input v-model="submit_info.english_name" placeholder="请填写英文名称"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">注册地址:</p>
									<div class="form_input w_377">
										<el-input v-model="submit_info.register_address" placeholder="请填写注册地址"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label" style="width: 128px;">邮编:</p>
									<div class="form_input w_266">
										<el-input v-model="submit_info.zip_code" placeholder="请填写邮编"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">电话:</p>
									<div class="form_input w_194">
										<el-input v-model="submit_info.mobile" placeholder="请填写电话"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">办公地址:</p>
									<div class="form_input w_377">
										<el-input v-model="submit_info.work_address" placeholder="请填写办公地址"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label" style="width: 128px;">统一社会信用代码:</p>
									<div class="form_input w_266">
										<el-input v-model="submit_info.credit_code" placeholder="请填写统一社会信用代码"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">法人代表:</p>
									<div class="form_input w_194">
										<el-input v-model="submit_info.legal_person" placeholder="请填写法人代表"></el-input>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>财务信息</p>
						</div>
						<div class="form_table_wrapper finance">
							<div class="info_wrapper">
								<div class="info_wrapper_block" style="display: block;">
									<div class="form_input_wrapper" style="padding-right: 3%;">
										<p class="form_input_label">开户行名称:</p>
										<div class="form_input form_all_input">
											<el-input v-model="submit_info.financeData.bank_name" placeholder="请填写开户行名称"></el-input>
										</div>
									</div>
								</div>
								<div class="info_wrapper_block">
									<div class="form_input_wrapper">
										<p class="form_input_label">银行账号:</p>
										<div class="form_input w_789">
											<el-input v-model="submit_info.financeData.bank_number" placeholder="请填写银行账号"></el-input>
										</div>
									</div>
								</div>
								<div class="info_wrapper_block">
									<div class="form_choose_wrapper">
										<p class="form_input_label">对账方式:</p>
										<div class="form_choose_content">
											<div class="form_choose_block" :class="{'active': submit_info.financeData.check_ways == 1}">
												<i @click="submit_info.financeData.check_ways = 1"></i>
												<p>单票</p>
											</div>
											<div class="form_choose_block" :class="{'active': submit_info.financeData.check_ways == 2}">
												<i @click="submit_info.financeData.check_ways = 2"></i>
												<p>多票</p>
											</div>
										</div>
									</div>
								</div>
								<div class="info_wrapper_block">
									<div class="form_choose_wrapper">
										<p class="form_input_label">结算账期:</p>
										<div class="form_choose_content" style="flex-wrap: wrap;width: 789px;">
											<div class="form_choose_block" style="min-width: 110px;" :class="{'active': item.id == submit_info.financeData.payment_days}"
											 v-for="(item, index) in payment_list" :key="index">
												<i @click="get_zhangqi(item)"></i>
												<p>{{item.name}}</p>
											</div>
										</div>
									</div>
								</div>
								<div class="info_wrapper_block">
									<div class="form_choose_wrapper">
										<p class="form_input_label">结算周期:</p>
										<div class="form_input w_194">
											<el-date-picker v-model="submit_info.financeData.interval" format="每月d号" value-format="d" type="date"
											 placeholder="选择日期"></el-date-picker>
										</div>
									</div>
								</div>
								<div class="info_wrapper_block" style="margin-top: 0;">
									<div class="form_choose_wrapper">
										<p class="form_input_label">结算方式:</p>
										<div class="form_choose_content square" style="flex-wrap: wrap;">
											<div class="form_choose_block" style="min-width: 110px;" :class="{'active': item.is_show}" v-for="(item, index) in way_list"
											 :key="index">
												<i @click="item.is_show = !item.is_show"></i>
												<p>{{item.name}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="form_wrapper_title">
							<p></p>
							<p>资源信息</p>
						</div>
						<div class="form_table_wrapper">
							<div class="form_table_add">
								<div class="form_table_add_block">
									<img src="../../assets/04@2x.png" @click="get_add_resource" />
									<p>新增资源</p>
								</div>
							</div>
							<div>
								<div class="form_th_wrapper">
									<div class="form_th_list">
										<div class="form_th_list_block">序号</div>
										<div class="form_th_list_block active">运输流向</div>
										<div class="form_th_list_block">线路</div>
										<div class="form_th_list_block active">航司/船司</div>
										<div class="form_th_list_block">级别</div>
										<div class="form_th_list_block active">操作</div>
									</div>
									<div class="form_th_list" v-for="(item, index) in resource_list" :key="index">
										<div class="form_th_list_block">{{index + 1}}</div>
										<div class="form_th_list_block active" v-if="!item.is_save">
											<el-select v-model="item.flow_direction_name" filterable placeholder="请选择" @change="get_change(1, $event, index)">
												<el-option v-for="item in transport_list" :key="item.value" :label="item.label" :value="item"></el-option>
											</el-select>
											<!-- <v-select :list="transport_list" :index="index" :value_name="item.flow_direction_name" @result="get_transport"></v-select> -->
										</div>
										<div class="form_th_list_block active" v-else>{{item.flow_direction_name}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-select v-model="item.circuit_name" filterable placeholder="请选择" @change="get_change(2, $event, index)">
												<el-option v-for="item in circuit_list" :key="item.value" :label="item.label" :value="item"></el-option>
											</el-select>
											<!-- <v-select :list="circuit_list" :index="index" :value_name="item.circuit_name" @result="get_circuit"></v-select> -->
										</div>
										<div class="form_th_list_block" v-else>{{item.circuit_name}}</div>
										<div class="form_th_list_block active" v-if="!item.is_save">
											<el-select v-model="item.name_name" filterable placeholder="请选择" @change="get_change(3, $event, index)">
												<el-option v-for="item in company_list" :key="item.value" :label="item.label" :value="item"></el-option>
											</el-select>
											<!-- <v-select :list="company_list" :index="index" :value_name="item.name_name" @result="get_company"></v-select> -->
										</div>
										<div class="form_th_list_block active" v-else>{{item.name_name}}</div>
										<div class="form_th_list_block" v-if="!item.is_save">
											<el-input v-model="item.level_name" placeholder="请输入级别"></el-input>
										</div>
										<div class="form_th_list_block" v-else>{{item.level_name}}</div>
										<div class="form_th_list_block operat active">
											<div class="c_5bbef0 pointer_" @click="get_resource_btn(item)">{{item.is_save?'编辑': '保存'}}</div>
											<div class="c_ff6f6f pointer_ m_l21" @click="get_resource_del(index, item)">删除</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block">
					<div class="form_table_add">
						<div class="form_table_add_block">
							<img src="../../assets/04@2x.png" @click="get_add_user" />
							<p>新增人员</p>
						</div>
					</div>
					<div class="form_right_add_list" v-for="(item, index) in user_list" :key="index">
						<div class="p_lr15 flex02" style="height: 42px;border-bottom: 1px solid #486AA5;">
							<div style="font-size: 18px;line-height: 25px;color: #5B8EF0;">0{{index + 1}}</div>
							<div class="operat_icon_wrapper">
								<i></i>
								<i @click="get_user_del(index, item)"></i>
							</div>
						</div>
						<div class="p_lr15">
							<div class="form_input_wrapper">
								<p class="form_input_label">联系人：</p>
								<div class="form_input">
									<el-input v-model="item.username" placeholder="请填写联系人"></el-input>
								</div>
							</div>
							<div class="form_input_wrapper">
								<p class="form_input_label">联系电话：</p>
								<div class="form_input">
									<el-input v-model="item.mobile" placeholder="请填写联系电话"></el-input>
								</div>
							</div>
							<div class="form_input_wrapper">
								<p class="form_input_label">联系邮箱：</p>
								<div class="form_input">
									<el-input v-model="item.email" placeholder="请填写联系邮箱"></el-input>
								</div>
							</div>
							<div class="form_input_wrapper" style="align-items: flex-start;">
								<p class="form_input_label" style="margin-top: 10px;">备注：</p>
								<div class="form_input">
									<el-input type="textarea" v-model="item.remark" placeholder="填写备注"></el-input>
								</div>
							</div>
						</div>
					</div>
					<div class="form_right_add_list" v-if="id">
						<div class="p_lr15 flex02" style="height: 42px;border-bottom: 1px solid #486AA5;">
							<div style="font-size: 18px;line-height: 25px;color: #5B8EF0;">企业资质</div>
						</div>
						<div class="p_lr15">
							<div class="form_input_wrapper" style="align-items: flex-start;">
								<p class="form_input_label" style="margin-top: 10px;">上传图片：</p>
								<div class="form_input flex01" style="flex-wrap: wrap;">
									<div style="margin: 15px 15px 15px 0;position: relative;" v-for="(item, index) in img_array" :key="index">
										<el-image style="width: 60px; height: 60px;cursor: pointer;" :src="item.image_text" fit="cover" @click="get_img_big(item.image_text)"></el-image>
										<i class="close_icon" v-if="bj" @click="get_img_close(item)"></i>
									</div>
									<el-upload v-if="bj" style="margin: 15px 15px 15px 0;font-size: 0;" :action="up_url" :show-file-list="false" multiple
									 :on-success="handleAvatarSuccess">
										<i class="add_img"></i>
									</el-upload>
									<el-dialog :visible.sync="dialogVisible">
										<img width="100%" :src="dialogImageUrl" alt="">
									</el-dialog>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form_wrapper" v-if="!id || bj">
				<div class="form_wrapper_block" style="width: 100%;">
					<div class="form_wrapper_btn_wrapper">
						<div class="btn01" @click="get_save_info">保存</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import country from '../../api/country.js'; // 世界国家数据
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select05 from '../../components/select/index05.vue'; // 模糊查询

	import {
		payDays,
		payType,
		transport,
		circuit,
		getAllList,
		companyAdd,
		companyDetail,
		companyResourceDel,
		companyLinkmanDel,
		companyEdit,
		companyImgAdd,
		companyImg,
		companyImgDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select': select05
		},
		data() {
			return {
				id: '',
				up_url: '',
				bj: false,
				period: '',
				dialogImageUrl: '',
				dialogVisible: false,
				img_array: [], // 资质
				gj_list: [], // 国家
				circuit_list: [], // 线路
				way_list: [], // 结算方式
				payment_list: [], // 结算账期
				company_list: [], // 航司/船司
				transport_list: [], // 运输流向
				crumbs_num: 17, // 17新增 170查看 171编辑			
				submit_info: {
					partner_type: '', // 合作性质 1:供应商,2:客户,3:其他
					is_partner: '', // 合作协议1:有,2:无
					contract_number: '', // 协议编号
					star_time: '', // 开始时间
					end_time: '', // 协议有效期结束时间 0000-00-00
					country_name: '', // 所在国家
					company_name: '', // 企业名称
					english_name: '', // 英文名称
					register_address: '', // 注册地址
					zip_code: '', // 邮编
					mobile: '', // 电话
					work_address: '', // 办公地址
					credit_code: '', // 统一社会信用代码
					legal_person: '', // 法人代表
					financeData: {
						bank_name: '', // 开户行名称
						bank_number: '', // 银行账号
						check_ways: '', // 对账方式 1：单票 2：多票
						payment_days: '', // 结算账期
						interval: '', // 结算周期
						pay_type: '', // 结算方式
					}, // 财务信息
					resource: [], // 资源
					linkman: [], // 联系人员
				},
				resource_list: [{
					is_save: false,
					flow_direction: '', // 运输流向
					flow_direction_name: '',
					circuit: '', // 线路
					circuit_name: '',
					name: '', // 航司/船司
					name_name: '',
					level_name: '', // 级别
				}], // 资源列表
				user_list: [{
					username: '', // 联系人姓名
					mobile: '', // 电话
					email: '', // 邮箱
					remark: '', // 描述
				}], // 新增人员
			}
		},
		created() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.get_imgs();
			}

			if (this.$route.query.bj) {
				this.bj = true
			}

			if (this.id && !this.bj) {
				this.crumbs_num = 170;
			}

			if (this.id && this.bj) {
				this.crumbs_num = 171;
			}

			let gj_list_ = country.get_country();
			gj_list_.forEach((n, k) => {
				this.gj_list.push({
					label: n,
					value: n
				})
			})

			this.getPayDays();
			this.getPayTypeList();
			this.getTransportList();
			this.getCircuitList();
			this.get_getAllList();

			this.up_url = this.$publicFun.get_up_img();
		},
		methods: {
			// 图片
			handleAvatarSuccess(e) {
				companyImgAdd({
					company_id: this.id,
					image: e.data
				}).then(res => {

				})
			},
			get_img_close(item) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					companyImgDel({
						id: item.id
					}).then(res => {
						this.get_imgs();
						this.$message.success('删除成功')
					})
				})				
			},
			get_img_big(url) {
				this.dialogImageUrl = url;
				this.dialogVisible = true;
			},
			get_imgs() {
				companyImg({
					company_id: this.id
				}).then(res => {
					this.img_array = res.data;
				})
			},
			// 详情数据
			get_detail() {
				companyDetail({
					id: this.id
				}).then(res => {
					let detail_info = res.data;
					let company_resource = res.data.company_resource; // 资源信息
					let finance = res.data.finance; // 财务信息
					let linkman = res.data.linkman; // 人员信息
					let pay_type = (finance.pay_type).split(','); // 计算方式

					finance.interval = String(finance.interval); // 结算周期
					// 计算方式
					this.way_list.forEach((n, k) => {
						pay_type.forEach((n_, k_) => {
							if (n.id == n_) {
								n.is_show = true;
							}
						})
					})

					detail_info.financeData = finance;

					this.submit_info = detail_info;

					// 两个数组
					company_resource.forEach((n, k) => {
						n.is_save = true;
					})

					this.resource_list = company_resource;
					this.user_list = linkman;

					this.$forceUpdate();
				})
			},
			// 国家
			get_result(e) {
				this.submit_info.country_name = e.label;
			},
			// 结算账期
			getPayDays() {
				payDays({
					page: 1,
					limit: 10000
				}).then(res => {
					this.payment_list = res.data.data;
				})
			},
			get_zhangqi(item) {
				this.submit_info.financeData.payment_days = item.id;
			},
			// 结算方式
			getPayTypeList() {
				payType({
					page: 1,
					limit: 10000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						n.is_show = false;
					})

					this.way_list = list_;

					if (this.id) {
						this.get_detail();
					}
				})
			},
			// 新增资源
			get_add_resource() {
				this.resource_list.push({
					is_save: false,
					flow_direction: '', // 运输流向
					flow_direction_name: '',
					circuit: '', // 线路
					circuit_name: '',
					name: '', // 航司/船司
					name_name: '',
					level_name: '' // 级别
				})
			},
			// 运输流向
			getTransportList() {
				transport({
					page: 1,
					limit: 10000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.transport_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			// 线路
			getCircuitList() {
				circuit({
					page: 1,
					limit: 10000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.circuit_list.push({
							label: n.name,
							value: n.id
						})
					})
				})
			},
			// 获取船司/航司
			get_getAllList() {
				getAllList({
					page: 1,
					limit: 20000
				}).then(res => {
					let list_ = res.data;

					list_.forEach((n, k) => {
						this.company_list.push({
							label: n.ship_name,
							value: n.id
						})
					})
				})
			},
			get_change(type, e, index) {
				// 运输流向
				if (type === 1) {
					this.resource_list[index].flow_direction = e.value;
					this.resource_list[index].flow_direction_name = e.label;
				}

				// 线路
				if (type === 2) {
					this.resource_list[index].circuit = e.value;
					this.resource_list[index].circuit_name = e.label;
				}

				// 航司/船司
				if (type === 3) {
					this.resource_list[index].name = e.value;
					this.resource_list[index].name_name = e.label;
				}
			},
			// 保存/编辑
			get_resource_btn(item) {
				for (let i in item) {
					if (!item[i] && i !== 'is_save' && i !== 'createtime_text') return this.$message('请将内容填写完整');
				}

				item.is_save = !item.is_save;
				this.$forceUpdate();
			},
			// 删除
			get_resource_del(index, item) {
				if (this.resource_list.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						companyResourceDel({
							id: item.id,
							company_id: this.id
						}).then(res => {
							this.resource_list.splice(index, 1);
						})
					} else {
						this.resource_list.splice(index, 1);
					}
				})
			},
			// 新增人员
			get_add_user() {
				this.user_list.push({
					username: '', // 联系人姓名
					mobile: '', // 电话
					email: '', // 邮箱
					remark: '' // 描述
				})
			},
			get_user_del(index, item) {
				if (this.user_list.length === 1) return this.$message.error('至少保留一条数据');

				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (item.id) {
						companyLinkmanDel({
							id: item.id,
							company_id: this.id
						}).then(res => {
							this.user_list.splice(index, 1);
						})
					} else {
						this.user_list.splice(index, 1);
					}
				})
			},
			// 保存
			get_save_info() {
				// 结算方式
				let way_array = [];
				this.way_list.forEach((n, k) => {
					if (n.is_show) {
						way_array.push(n.id);
					}
				})

				if (way_array.length !== 0) this.submit_info.financeData.pay_type = way_array.join(",");

				// 资源
				let resource_array = [];
				this.resource_list.forEach((n, k) => {
					if (n.is_save) {
						resource_array.push(n);
						// resource_array.push({
						// 	flow_direction: n.flow_direction, // 运输流向
						// 	circuit: n.circuit, // 线路
						// 	name: n.name, // 航司/船司
						// 	level_name: n.level_name, // 级别
						// });
					}
				})

				if (resource_array.length !== 0) this.submit_info.resource = resource_array;

				// 人员
				let user_info_show = true;
				this.user_list.forEach((n, k) => {
					for (let i in n) {
						if (!n[i]) {
							user_info_show = false;
						}
					}
				})

				if (user_info_show) this.submit_info.linkman = this.user_list;

				// 验证
				// for (let i in this.submit_info) {
				// 	if (i !== 'financeData' && i !== 'resource' && i !== 'linkman' && i !== 'createtime_text' && !this.submit_info[i])
				// 		return this.$message(
				// 			'请将内容填写完整');
				// }

				for (let i in this.submit_info.financeData) {
					if (!this.submit_info.financeData[i] && i !== 'createtime_text') return this.$message('请将财务信息内容填写完整');
				}

				if (resource_array.length === 0) return this.$message('请至少填写一条资源信息');
				if (this.submit_info.linkman.length === 0) return this.$message('请至少填写一条人员信息');

				console.log('提交信息', this.submit_info)

				// 添加
				if (!this.id) {
					companyAdd(this.submit_info).then(res => {
						this.$message.success('添加成功！');
						this.$publicFun.get_link('/info/enterprise/list', {});
					})
				}

				// 编辑
				if (this.id && this.bj) {
					companyEdit(this.submit_info).then(res => {
						this.$message.success('编辑成功！');
						this.$publicFun.get_link('/info/enterprise/list', {});
					})
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	.w_377 {
		width: 377px;
	}

	.form_wrapper .form_input.w_377 {
		width: 377px;
	}

	.form_wrapper .form_input.w_266 {
		width: 266px;
	}

	.form_wrapper .form_input.w_789 {
		width: 789px;
	}

	.form_wrapper .form_choose_wrapper .form_input_label {
		margin-left: 0;
		margin-top: 10px;
	}

	.form_wrapper .info_wrapper.top .form_choose_block {
		min-width: 0;
		// margin-top: 10px;
		margin-right: 30px;
		// margin-bottom: 10px;
	}

	.form_wrapper .form_all_input {
		flex: 1;
	}

	.form_line_ {
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			width: 17px;
			border-bottom: 1px solid #fff;
		}
	}

	.form_table_wrapper.finance .form_choose_block {
		&:last-child {
			min-width: 93px;
			margin-right: 0;
		}
	}

	.form_wrapper .form_wrapper_right {
		padding: 0 18px;

		.form_table_add {
			padding: 0 9px;
		}
	}

	.form_right_add_list {
		border-radius: 10px;
		margin: 15px 0;
		border: 1px solid #486AA5;
	}

	.form_choose_content.square {
		.form_choose_block {
			&.active {
				i {
					background: url(../../assets/06@2x.png) no-repeat center;
					background-size: 100%;
				}
			}

			i {
				background: url(../../assets/05@2x.png) no-repeat center;
				background-size: 100%;
			}
		}
	}

	.operat_icon_wrapper {
		display: flex;

		i {
			width: 17px;
			height: 17px;
			display: block;
			cursor: pointer;

			&:first-child {
				background: url(../../assets/07@2x.png) no-repeat center;
				background-size: 100% 100%;
				margin-right: 11px;
			}

			&:nth-child(2) {
				background: url(../../assets/08@2x.png) no-repeat center;
				background-size: 100% 100%;
			}
		}
	}

	// 资质图片
	.add_img {
		width: 60px;
		height: 60px;
		display: block;
		background: url(../../assets/wx/06@2x.png) no-repeat center;
		background-size: 100% 100%;
	}
</style>
